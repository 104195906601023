import axios from 'common/api/api'
import {
  CLIENT,
  GENERAL,
  HOME_PAGE,
  LOGIN,
  NOTES,
  PICK_UP,
  REQUESTS,
  TOKEN,
} from 'common/const/navigation.consts'
import Logo from 'common/icons/png/LOGO.png'
import React, { FC } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { removeStorage } from '../../common/services/storage.service'
import {
  DisabledNavigationLink,
  HeaderWrapper,
  LogoContainer,
  LogoIcon,
  LogoutLink,
  NavigationContainer,
  NavigationLink,
  RequestsContainer,
  RequestsLink,
} from './Header.styled'
import { Props } from './Header.types'

export const Header: FC<Props> = ({
  isNavDisabled = false,
  isAddressNavDisabled,
}) => {
  const params = useParams()
  const navigate = useNavigate()

  const logOut = async () => {
    try {
      const companyToken = localStorage.getItem('company_token')
      await axios.post(`user/logout`)
      removeStorage(TOKEN)

      navigate(companyToken ? LOGIN + `/${companyToken}` : LOGIN)
    } catch (e) {
      console.error(e, 'Error')
    }
  }

  return (
    <HeaderWrapper>
      <LogoContainer>
        <LogoIcon src={Logo} />
      </LogoContainer>
      <NavigationContainer>
        <NavigationLink end to={HOME_PAGE}>
          Students
        </NavigationLink>
        {isNavDisabled ? (
          <>
            <DisabledNavigationLink>General information</DisabledNavigationLink>
            <DisabledNavigationLink>Addresses</DisabledNavigationLink>
            <DisabledNavigationLink>
              Notes to transportation
            </DisabledNavigationLink>
          </>
        ) : (
          <>
            <NavigationLink end to={`${CLIENT}/${params.id}${GENERAL}`}>
              General information
            </NavigationLink>
            {isAddressNavDisabled ? (
              <DisabledNavigationLink>Addresses</DisabledNavigationLink>
            ) : (
              <NavigationLink end to={`${CLIENT}/${params.id}${PICK_UP}`}>
                Addresses
              </NavigationLink>
            )}
            <NavigationLink end to={`${CLIENT}/${params.id}${NOTES}`}>
              Notes to transportation
            </NavigationLink>
          </>
        )}
      </NavigationContainer>
      <RequestsContainer>
        <RequestsLink to={REQUESTS}>
          Requests
          {/* TODO Requests count will be used in future */}
          {/* <RequestsCounter>3</RequestsCounter> */}
        </RequestsLink>
        <LogoutLink onClick={logOut}>Logout</LogoutLink>
      </RequestsContainer>
    </HeaderWrapper>
  )
}
