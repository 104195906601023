import './index.css'

import { Col, Radio, Row, Select } from 'antd'
import { Divider } from 'antd/es'
import { RadioChangeEvent } from 'antd/lib/radio/interface'
import axios from 'common/api/api'
import { COLORS } from 'common/const/colors.const'
import { LOGIN_COMPANY_NO_TOKEN } from 'common/const/navigation.consts'
import { StudentAddress } from 'common/context/RegistrationProvider'
import { useRegistrationContext } from 'common/hooks/useRegistrationContext'
import { MainWrapper, ModalTitle, Title } from 'common/styles/common.styled'
import { Button } from 'components/Button'
import { InputWithLabel } from 'components/InputWithLabel'
import { LeftRightButtons } from 'components/LeftRightButtons'
import { LayoutRegistration } from 'layouts/Layout'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ModalWindow } from '../../components/ModalWindow'
import { ModalContainer, ModalText } from '../GeneralInfo/GeneralInfo.styled'
import { AddressGeo } from '../PickUp'
import {
  AddressErrorMessage,
  HintText,
  Label,
  SelectionBlock,
  StudentIdBlock,
  StudentIdYellowBlock,
} from './RegistrationAddresses.styled'

export const RegistrationAddresses = () => {
  const { students, setAddresses, setAddressesType, setAddressesEditing } =
    useRegistrationContext()
  const { token, cid } = useParams()
  const navigate = useNavigate()

  const [pickUpAddress, setPickUpAddress] = useState('')
  const [dropOffAddress, setDropOffAddress] = useState('')
  const [pickUpSchool, setPickUpSchool] = useState('')
  const [dropOffSchool, setDropOffSchool] = useState('')
  const [companySchools, setCompanySchools] = useState([])
  const [canGoNext, setCanGoNext] = useState(false)
  const [isDataUpdated, setIsDataUpdated] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [pickUpError, setPickUpError] = useState(false)
  const [dropOffError, setDropOffError] = useState(false)
  const [pickUpErrorLength, setPickUpErrorLength] = useState(false)
  const [dropOffErrorLength, setDropOffErrorLength] = useState(false)

  const isStudentData = students?.find(
    student => student.cid && student.birthdate
  )
  const getCompanyInfo = async () => {
    try {
      if (token) {
        const response = await axios.get<{
          name: string
          s3Link: string
          schools: []
        }>(`company/info/${token}`)
        const data = response?.data
        if (data) {
          // filter schools by non-empty values
          const schools = data.schools.filter(school => school)
          setCompanySchools(schools)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  const validateAddresses = async (address: string, type: number) => {
    try {
      if (address) {
        if (type === 1) {
          setPickUpErrorLength(address.length < 6)
        }
        if (type === 2) {
          setDropOffErrorLength(address.length < 6)
        }

        const response = await axios.get<AddressGeo>(
          `/user/geocode?address=${address}`
        )
        const data: AddressGeo = response?.data

        if (type === 1) {
          setPickUpError(!data.lat && !data.lon)
          setAddressesEditing(cid as string, 1, Boolean(!data.lat && !data.lon))
        }

        if (type === 2) {
          setDropOffError(!data.lat && !data.lon)
          setAddressesEditing(cid as string, 2, Boolean(!data.lat && !data.lon))
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    void getCompanyInfo()
  }, [])

  useEffect(() => {
    if (!isStudentData) navigate(`${LOGIN_COMPANY_NO_TOKEN}/${token}`)
  }, [])

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      e.preventDefault()
      e.returnValue = ''
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  //find the index of the current student in the students array by cid
  const filteredStudents = students.filter(
    ({ cid, birthdate }) => cid && birthdate
  )
  const currentStudentIndex = filteredStudents.findIndex(
    student => student.cid === cid
  )

  const prevPage = useMemo(() => {
    const prevStudent = filteredStudents[currentStudentIndex - 1]
    if (prevStudent?.cid) {
      return `/client/addresses/${token}/${prevStudent.cid}`
    }
    return `/client/registration/${token}`
  }, [currentStudentIndex, students, token])

  const nextPage = useMemo(() => {
    const nextStudent = filteredStudents[currentStudentIndex + 1]
    if (nextStudent?.cid) {
      return `/client/addresses/${token}/${nextStudent.cid}`
    }
    return `/client/guardian/${token}`
  }, [currentStudentIndex, students, token])

  const currentStudent = filteredStudents[currentStudentIndex]
  const addressesType = currentStudent?.addressesType
  //if student has no addresses or 2 addresses, then addressesType = 3
  //if student has 1 address, then addressesType equal to the type of the address
  useEffect(() => {
    if (
      currentStudent?.addresses?.length === 0 ||
      currentStudent?.addresses?.length === 2
    ) {
      setAddressesType(cid as string, 3)
    } else {
      setAddressesType(cid as string, currentStudent?.addresses[0].type)
    }
  }, [])

  const pickupAddress = currentStudent?.addresses?.find(
    address => address.type === 1
  )
  const dropoffAddress = currentStudent?.addresses?.find(
    address => address.type === 2
  )

  const onChange = (e: RadioChangeEvent) => {
    setAddressesType(cid as string, e.target.value as number)
  }

  const onPickupAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPickUpAddress(e.target.value)
    setPickUpError(false)
    setAddressesEditing(cid as string, 1, true)
  }
  const onDropoffAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDropOffAddress(e.target.value)
    setDropOffError(false)
    setAddressesEditing(cid as string, 2, true)
  }
  const onPickupSchoolChange = (value: string) => {
    setPickUpSchool(value)
  }
  const onDropoffSchoolChange = (value: string) => {
    setDropOffSchool(value)
  }

  useEffect(() => {
    if (!isDataUpdated) {
      setPickUpAddress(pickupAddress?.address || '')
      setPickUpSchool(pickupAddress?.school || '')

      setDropOffAddress(dropoffAddress?.address || '')
      setDropOffSchool(dropoffAddress?.school || '')

      setIsDataUpdated(true)
    }
  }, [pickupAddress, dropoffAddress, isDataUpdated])

  const setAddressToContext = () => {
    const addresses: StudentAddress[] = []

    if (pickUpAddress && pickUpSchool) {
      addresses.push({
        type: 1,
        address: pickUpAddress,
        school: pickUpSchool,
      })
    }

    if (dropOffAddress && dropOffSchool) {
      addresses.push({
        type: 2,
        address: dropOffAddress,
        school: dropOffSchool,
      })
    }

    setAddresses(currentStudent?.cid, addresses)

    const isPickupExists = addresses.find(({ type }) => type === 1)
    const isDropoffExists = addresses.find(({ type }) => type === 2)

    const ifPickupAddress = addressesType === 1 && Boolean(isPickupExists)
    const ifDropoffAddress = addressesType === 2 && Boolean(isDropoffExists)
    const ifBothAddresses = addressesType === 3 && addresses.length === 2
    const isCanGoNext = ifPickupAddress || ifDropoffAddress || ifBothAddresses
    setCanGoNext(isCanGoNext)
  }

  useEffect(() => {
    setAddressToContext()
  }, [
    pickUpAddress,
    dropOffAddress,
    pickUpSchool,
    dropOffSchool,
    addressesType,
  ])

  const handleQuit = () => {
    navigate(`${LOGIN_COMPANY_NO_TOKEN}/${token}`)
  }

  const isAddressesValid = useMemo(() => {
    if (addressesType === 3) {
      return (
        !pickUpError &&
        !dropOffError &&
        !currentStudent?.editingDropOff &&
        !currentStudent?.editingPickUp
      )
    }
    return addressesType === 1
      ? !pickUpError && !currentStudent?.editingPickUp
      : !dropOffError && !currentStudent?.editingDropOff
  }, [
    pickUpError,
    dropOffError,
    addressesType,
    currentStudent?.editingPickUp,
    currentStudent?.editingDropOff,
  ])

  return (
    <LayoutRegistration>
      <MainWrapper>
        <LeftRightButtons
          onLeftClick={() => setIsDataUpdated(false)}
          onRightClick={() => setIsDataUpdated(false)}
          left={`${prevPage}`}
          right={canGoNext && isAddressesValid ? nextPage : undefined}
        >
          <Title>Pickup and Drop off information</Title>
          <HintText>
            Please select Pickup, Drop off or Both Services for the following
            Student/Rider ID. Also provide From and To address information.
          </HintText>
          <Row>
            <Col span={9}>
              <StudentIdBlock>
                Student ID: <StudentIdYellowBlock>{cid}</StudentIdYellowBlock>
              </StudentIdBlock>
            </Col>
            <Col span={3}>
              <SelectionBlock>Requires:</SelectionBlock>
            </Col>

            <Col span={10}>
              <SelectionBlock>
                <Radio.Group value={addressesType} onChange={onChange}>
                  <Radio value={1}>Pickup</Radio>
                  <Radio value={2}>Drop off</Radio>
                  <Radio value={3}>Both</Radio>
                </Radio.Group>
              </SelectionBlock>
            </Col>
          </Row>
          <Divider />
          {addressesType === 1 || addressesType === 3 ? (
            <div>
              <Title>Pickup / Morning</Title>
              <InputWithLabel
                errorBorder={pickUpError || pickUpErrorLength}
                label={'From'}
                width={400}
                placeholder={'Enter home address'}
                value={pickUpAddress}
                onChange={onPickupAddressChange}
                onBlur={e => validateAddresses(e.target.value, 1)}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    void validateAddresses(e.target.value, 1)
                  }
                }}
              />
              {pickUpErrorLength ? (
                <AddressErrorMessage>
                  Address length should be at least 6 symbols
                </AddressErrorMessage>
              ) : (
                pickUpError && (
                  <AddressErrorMessage>Incorrect Address</AddressErrorMessage>
                )
              )}
              <Label>To School:</Label>
              <br />
              <Select
                style={{ width: 400 }}
                value={pickUpSchool}
                onChange={onPickupSchoolChange}
              >
                {companySchools.map((school, index) => (
                  <Select.Option
                    key={index}
                    value={school}
                    placeholder='School name on file'
                  >
                    {school}
                  </Select.Option>
                ))}
              </Select>
            </div>
          ) : null}
          {addressesType === 2 || addressesType === 3 ? (
            <div>
              <Title>Drop Off / Afternoon</Title>
              <Label>From School:</Label>
              <br />
              <Select
                style={{ width: 400 }}
                value={dropOffSchool}
                onChange={onDropoffSchoolChange}
              >
                {companySchools.map((school, index) => (
                  <Select.Option
                    key={index}
                    value={school}
                    placeholder='School name on file'
                  >
                    {school}
                  </Select.Option>
                ))}
              </Select>
              <InputWithLabel
                errorBorder={dropOffError || dropOffErrorLength}
                label={'To'}
                width={400}
                placeholder={'Enter home address'}
                value={dropOffAddress}
                onChange={onDropoffAddressChange}
                onBlur={e => validateAddresses(e.target.value, 2)}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    void validateAddresses(e.target.value, 2)
                  }
                }}
              />
              {dropOffErrorLength ? (
                <AddressErrorMessage>
                  Address length should be at least 6 symbols
                </AddressErrorMessage>
              ) : (
                dropOffError && (
                  <AddressErrorMessage>Incorrect Address</AddressErrorMessage>
                )
              )}
            </div>
          ) : null}
          <Divider />
          <HintText>
            If you have multiple addresses please contact transportation when
            your account is created.
          </HintText>
          <Row>
            <Col span={4} offset={20}>
              <Button
                background={`${COLORS.BUTTON_GRAY}`}
                disabledBackground={`${COLORS.BUTTON_GRAY}`}
                width='100px'
                name='Quit'
                type='submit'
                onClick={() => setCancelModal(true)}
              />
            </Col>
          </Row>
        </LeftRightButtons>
        <ModalWindow
          width={372}
          mgTop={95}
          visible={cancelModal}
          onCloseClick={() => setCancelModal(false)}
          onNoClick={() => setCancelModal(false)}
          onYesClick={() => {
            handleQuit()
            setCancelModal(false)
          }}
        >
          <ModalContainer>
            <ModalTitle>Quit</ModalTitle>
            <ModalText>All your changes will be lost, are you sure?</ModalText>
          </ModalContainer>
        </ModalWindow>
      </MainWrapper>
    </LayoutRegistration>
  )
}
