import { COLORS } from 'common/const/colors.const'
import styled from 'styled-components'

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 80px;
  position: fixed;
  z-index: 5;
  bottom: 0;
  left: 0;
  background-color: ${COLORS.WHITE};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.21);
  width: 100%;
`
export const ButtonsBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 50px;
  width: 296px;
`

export const ButtonDiv = styled.div`
  &:not(:last-child) {
    margin-right: 10px;
  }
`
