import React, { FC, PropsWithChildren } from 'react'

import { PagesContainerRegistration, PagesWrapper } from './Layout.styled'
import { Props } from './Layout.types'

export const LayoutRegistration: FC<PropsWithChildren<Props>> = ({
  children,
}) => {
  return (
    <>
      <PagesWrapper>
        <PagesContainerRegistration>{children}</PagesContainerRegistration>
      </PagesWrapper>
    </>
  )
}
