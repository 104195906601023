import axios from 'common/api/api'
import { COLORS } from 'common/const/colors.const'
import { requestFields, requestStatuses } from 'common/const/request'
import { ModalTitle } from 'common/styles/common.styled'
import { RequestFieldKeys } from 'common/types/request'
import { Button } from 'components/Button'
import { CommentBlock } from 'components/CommentBlock'
import { InfoBlock } from 'components/InfoBlock'
import { ModalWindow } from 'components/ModalWindow'
import { TextArea } from 'components/TextArea'
import { format } from 'date-fns'
import React, { FC, useEffect, useState } from 'react'

import { StatusColor } from '../RequestInfo'
import {
  BorderLine,
  ChangeInfo,
  InfoBlockWrapper,
  ModalScrollContainer,
  ModalTitleBlock,
  RequestStatus,
  RequestType,
  SendButtonContainer,
} from './RequestModal.styled'
import {
  MarkMessagesType,
  MessagesResponse,
  MessageType,
  Props,
  SendMessageType,
} from './RequestModal.types'

export const RequestModal: FC<Props> = ({
  requestData,
  visible,
  onCloseClick,
  updateRequests,
}) => {
  const [messages, setMessages] = useState<MessageType[]>([])
  const [commentCurrentValue, setCommentCurrentValue] = useState('')

  const postMarkAsReadMessages = async (messages: MessageType[]) => {
    const messagesIds = messages?.map(({ id }) => id)
    try {
      const markMessagesData: MarkMessagesType = {
        requestId: requestData?.id,
        messageIds: messagesIds,
      }
      await axios.post(`requests/messages/mark-as-read`, markMessagesData)
      updateRequests()
    } catch (e) {
      console.error(e)
    }
  }

  const getMessages = async () => {
    try {
      const response = await axios.get<MessagesResponse>(
        `requests/messages/index/${requestData?.id}`
      )
      const data = response?.data

      if (data) {
        setMessages(data)
        if (data.length) {
          await postMarkAsReadMessages(data)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (visible && requestData) {
      void getMessages()
    }
  }, [visible, requestData])
  const [isLoading, setIsLoading] = useState(false)

  const sendMessage = async () => {
    try {
      setIsLoading(true)
      const messageRequestData: SendMessageType = {
        requestId: requestData?.id,
        text: commentCurrentValue,
      }
      await axios.post(`requests/messages/send`, messageRequestData)
      setCommentCurrentValue('')
      updateRequests()
      await getMessages()
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ModalWindow
      visible={visible}
      onCloseClick={() => {
        onCloseClick()
        setCommentCurrentValue('')
      }}
    >
      <ModalScrollContainer>
        {requestData && (
          <>
            <ModalTitleBlock>
              <ModalTitle>
                Request change{' '}
                {requestData?.date
                  ? format(requestData?.date * 1000, 'MM/dd/yyyy')
                  : ''}{' '}
                {requestData?.date
                  ? format(requestData?.date * 1000, 'h:mm a')
                  : ''}
              </ModalTitle>
              <RequestStatus>
                {(requestStatuses[requestData?.status] === 'Accepted' ||
                  requestStatuses[requestData?.status] === 'Rejected') && (
                  <StatusColor
                    color={
                      requestStatuses[requestData?.status] === 'Accepted'
                        ? COLORS.STATUS_GREEN
                        : COLORS.STATUS_RED
                    }
                  />
                )}
                {requestStatuses[requestData?.status]}
              </RequestStatus>
            </ModalTitleBlock>
            {requestData?.changes?.map(
              ({ oldFields, newFields, type, entityType }) => {
                if (type === 'create') {
                  return (
                    <>
                      <RequestType>Create</RequestType>
                      {newFields &&
                        Object.keys(newFields).map((key: string) => (
                          <InfoBlock
                            key={key}
                            label={
                              entityType === 'note'
                                ? 'Notes to transportation'
                                : requestFields[key as RequestFieldKeys]
                            }
                            value={newFields[key as RequestFieldKeys]}
                          />
                        ))}
                      <BorderLine />
                    </>
                  )
                }
                if (type === 'delete') {
                  return (
                    <>
                      <RequestType>Delete</RequestType>
                      {oldFields &&
                        Object.keys(oldFields).map(key => (
                          <InfoBlock
                            key={key}
                            label={
                              entityType === 'note'
                                ? 'Notes to transportation'
                                : requestFields[key as RequestFieldKeys]
                            }
                            value={oldFields[key as RequestFieldKeys]}
                          />
                        ))}
                      <BorderLine />
                    </>
                  )
                }
                if (type === 'update') {
                  return (
                    <>
                      <RequestType>Update</RequestType>
                      {oldFields &&
                        Object.keys(oldFields).map(key => (
                          <InfoBlockWrapper key={key}>
                            <InfoBlock
                              label={
                                entityType === 'note'
                                  ? 'Notes to transportation'
                                  : requestFields[key as RequestFieldKeys]
                              }
                              value={oldFields[key as RequestFieldKeys]}
                            />
                          </InfoBlockWrapper>
                        ))}
                      <ChangeInfo>to</ChangeInfo>
                      {newFields &&
                        Object.keys(newFields).map(key => (
                          <InfoBlockWrapper key={key}>
                            <InfoBlock
                              label={
                                entityType === 'note'
                                  ? 'Notes to transportation'
                                  : requestFields[key as RequestFieldKeys]
                              }
                              value={newFields[key as RequestFieldKeys]}
                            />
                          </InfoBlockWrapper>
                        ))}
                      <BorderLine />
                    </>
                  )
                }
              }
            )}
            <TextArea
              name={'comment'}
              width={568}
              height={35}
              placeholder='Leave comment'
              value={commentCurrentValue}
              onChange={e => {
                setCommentCurrentValue(e.target.value)
              }}
            >
              {commentCurrentValue && (
                <SendButtonContainer>
                  <Button
                    name='Send'
                    width='56px'
                    height='30px'
                    fontSize='12px'
                    disabled={isLoading}
                    onClick={sendMessage}
                  />
                </SendButtonContainer>
              )}
            </TextArea>
            {messages?.map(({ createdAt, text, author, id }) => {
              return (
                <CommentBlock
                  key={id}
                  title={author}
                  createdTime={format(createdAt * 1000, 'h:mm a')}
                  text={text}
                />
              )
            })}
          </>
        )}
      </ModalScrollContainer>
    </ModalWindow>
  )
}
