import { create } from 'zustand'

import { Student } from '../../../common/types/students'
import { StudentStopInfoProps } from '../components/StudentInfo'

interface IStore {
  students: Student[]
  isLoadingStudents: boolean
  setStore: (params: Partial<IStore>) => void
  setNewYearState: (id: number, v: boolean) => void
  isActiveStudent: number | null
  studentInfo: Record<string, StudentStopInfoProps[]>
}

export const useHomePageStore = create<IStore>((set, get) => ({
  students: [],
  isLoadingStudents: false,
  isActiveStudent: null,
  studentInfo: {},
  setStore: params => set({ ...params }),
  setNewYearState: (id, state) =>
    set({
      students: get().students.map(student => {
        console.log(student)
        if (student.id === id) {
          return {
            ...student,
            nextYear: {
              ...student.nextYear,
              state,
              name: student.nextYear?.name,
            },
          }
        } else {
          return student
        }
      }),
    }),
}))
export const infoKeys: Record<string, string> = {
  stopName: 'Stop name',
  stopAddress: 'Stop address',
  stopTime: 'Stop time',
  runName: 'Run name',
  bus: 'Bus',
  driver: 'Driver',
}
