import { CLIENT, GENERAL, NOTES } from 'common/const/navigation.consts'
import { useFormContext } from 'common/hooks/useFormContext'
import { useStudentsContext } from 'common/hooks/useStudentsContext'
import {
  BlockTitle,
  InputContainer,
  InputsBlock,
  MainWrapper,
  RadioButtonBLock,
  Title,
} from 'common/styles/common.styled'
import { Student } from 'common/types/students'
import { InfoBlock } from 'components/InfoBlock'
import { InputWithLabel } from 'components/InputWithLabel'
import { LeftRightButtons } from 'components/LeftRightButtons'
import { RadioButton } from 'components/RadioButton'
import { Layout } from 'layouts/Layout'
import React from 'react'
import { useParams } from 'react-router-dom'

import { AlertError, InfoBlockWrapper } from './PickUp.styled'

export const PickUp = () => {
  const {
    addressesState: {
      incorrectPickUpAddress,
      incorrectDropOffAddress,
      addressType,
      pickUpValue,
      dropOffValue,
      primaryPickUpAddress,
      primaryDropOffAddress,
      handleChangeAddressType,
      handlePickUpChange,
      handleDropOffChange,
    },
  } = useFormContext()
  const { students } = useStudentsContext()
  const { id } = useParams()

  const findStudent = (student: Student) => {
    return student.id === Number(id)
  }

  const student: Student | undefined = students.find(findStudent)

  return (
    <Layout>
      <MainWrapper>
        <LeftRightButtons
          left={`${CLIENT}/${id}${GENERAL}`}
          right={`${CLIENT}/${id}${NOTES}`}
        >
          <>
            <Title>{student?.fullName}</Title>

            <RadioButtonBLock>
              <RadioButton
                checked={addressType === 1}
                onChange={() => handleChangeAddressType(1)}
                name='isPickUp'
                label='Pickup'
              />
              <RadioButton
                checked={addressType === 2}
                onChange={() => handleChangeAddressType(2)}
                name='isPickUp'
                label='Drop Off'
              />
              <RadioButton
                checked={addressType === 3}
                onChange={() => handleChangeAddressType(3)}
                name='isPickUp'
                label='Both'
              />
            </RadioButtonBLock>
            {(addressType === 1 || addressType === 3) && (
              <>
                <BlockTitle>Pickup / Morning Address</BlockTitle>
                <InfoBlockWrapper>
                  <InfoBlock
                    label='School'
                    value={primaryPickUpAddress?.schoolName || ''}
                  />
                </InfoBlockWrapper>
                <InputsBlock noBorder>
                  <InputContainer>
                    <InputWithLabel
                      errorBorder={incorrectPickUpAddress}
                      width={743}
                      label='Address'
                      name='address'
                      value={pickUpValue}
                      onChange={e => handlePickUpChange(e.target.value)}
                    />
                  </InputContainer>
                </InputsBlock>
                {incorrectPickUpAddress && (
                  <AlertError>Incorrect Address</AlertError>
                )}
              </>
            )}
            {(addressType === 2 || addressType === 3) && (
              <>
                <BlockTitle>Drop Off / Afternoon Address</BlockTitle>
                <InfoBlockWrapper>
                  <InfoBlock
                    label='School'
                    value={primaryDropOffAddress?.schoolName || ''}
                  />
                </InfoBlockWrapper>
                <InputsBlock noBorder>
                  <InputContainer>
                    <InputWithLabel
                      errorBorder={incorrectDropOffAddress}
                      width={743}
                      label='Address'
                      name='address'
                      value={dropOffValue}
                      onChange={e => handleDropOffChange(e.target.value)}
                    />
                  </InputContainer>
                </InputsBlock>
                {incorrectDropOffAddress && (
                  <AlertError>Incorrect Address</AlertError>
                )}
              </>
            )}
          </>
        </LeftRightButtons>
      </MainWrapper>
    </Layout>
  )
}
