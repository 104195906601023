import { COLORS } from 'common/const/colors.const'
import styled from 'styled-components'

export const MainWrapper = styled.div`
  padding: 36px 28px;
  width: 100%;
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  background-color: ${COLORS.DARK_BLUE};
`

export const TitleLeftBlock = styled.div`
  width: 50%;
  padding: 16px 158px 15px 20px;
  display: flex;
  justify-content: space-between;
`

export const TitleRightBlock = styled.div`
  width: 50%;
  padding: 16px 108px 15px 0;
  display: flex;
  justify-content: space-between;
`

export const StyledSpan = styled.span`
  font-family: HelveticaNeue-Medium, sans-serif;
  color: ${COLORS.WHITE};
  font-size: 16px;
`

export const Title = styled.h2`
  color: ${COLORS.MAIN_TEXT_BLUE};
  font-family: HelveticaNeue, sans-serif;
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 500;
`
