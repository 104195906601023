import { CLIENT, GENERAL, PICK_UP } from 'common/const/navigation.consts'
import { useFormContext } from 'common/hooks/useFormContext'
import { useStudentsContext } from 'common/hooks/useStudentsContext'
import { Title } from 'common/styles/common.styled'
import { Student } from 'common/types/students'
import { CommentBlock } from 'components/CommentBlock'
import { LeftRightButtons } from 'components/LeftRightButtons'
import { TextArea } from 'components/TextArea'
import { format } from 'date-fns'
import { Layout } from 'layouts/Layout'
import React from 'react'
import { useParams } from 'react-router-dom'

import { MainWrapper } from './NotesToTransportation.styled'

export const NotesToTransportation = () => {
  const {
    notesState: { notes, notesCurrentValue, setNotesCurrentValue },
    generalInfoState: { values },
  } = useFormContext()
  const { id } = useParams()
  const { students } = useStudentsContext()

  const findStudent = (student: Student) => {
    return student.id === Number(id)
  }

  const student: Student | undefined = students.find(findStudent)

  const leftButton = `${CLIENT}/${id}${values?.isRiding ? PICK_UP : GENERAL}`

  return (
    <Layout isAddressNavDisabled={!values?.isRiding}>
      <MainWrapper>
        <LeftRightButtons left={leftButton}>
          <Title>{student?.fullName}</Title>
          <TextArea
            name='noteText'
            width={743}
            label='Notes to Transportation'
            value={notesCurrentValue}
            onChange={e => setNotesCurrentValue(e.target.value)}
          />

          {notes?.map(({ createdAt, text, id }): JSX.Element => {
            return (
              <CommentBlock
                key={id}
                title={
                  createdAt
                    ? format(new Date(createdAt * 1000), 'MM/dd/yyyy')
                    : ''
                }
                createdTime={
                  createdAt ? format(createdAt * 1000, 'h:mm a') : ''
                }
                text={text}
              />
            )
          })}
        </LeftRightButtons>
      </MainWrapper>
    </Layout>
  )
}
