import React, { FC, PropsWithChildren } from 'react'

import { NavigationButton } from './LeftRightButtons.styled'
import { Props } from './LeftRightButtons.types'

export const LeftRightButtons: FC<PropsWithChildren<Props>> = ({
  left,
  right,
  onLeftClick,
  onRightClick,
  children,
}) => {
  //@eslint-disable-next-line
  return (
    <>
      {left && <NavigationButton onClick={onLeftClick} to={left} left />}
      {children}
      {right && <NavigationButton onClick={onRightClick} to={right} />}
    </>
  )
}
