import { StudentsContext } from 'common/context/StudentsProvider'
import { useContext } from 'react'

export const useStudentsContext = () => {
  const { students, fetchStudentsList } = useContext(StudentsContext)

  return {
    students,
    fetchStudentsList,
  }
}
