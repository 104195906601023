import { COLORS } from 'common/const/colors.const'
import styled from 'styled-components'

export const ModalTitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`

export const RequestStatus = styled.div`
  position: relative;
  font-family: HelveticaNeue, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${COLORS.BLACK};
`

export const ChangeInfo = styled.div`
  font-family: HelveticaNeue, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: ${COLORS.BLACK};
  margin: 20px 0;
`

export const BorderLine = styled.div`
  border-bottom: 1px solid #e5e5e5;
  margin: 20px -16px;
`

export const RequestType = styled.p`
  font-family: HelveticaNeue, sans-serif;
  font-weight: 700;
  font-size: 17px;
`

export const InfoBlockWrapper = styled.div`
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`

export const SendButtonContainer = styled.div`
  padding: 5px 10px 10px 10px;
`

export const ModalScrollContainer = styled.div`
  height: 100%;
  overflow-y: auto;
`
