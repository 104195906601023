import { COLORS } from 'common/const/colors.const'
import styled from 'styled-components'

export const MainWrapper = styled.div`
  padding: 36px 28px;
  position: relative;
  width: 100%;
`

export const Title = styled.h2`
  color: ${COLORS.MAIN_TEXT_BLUE};
  margin-bottom: 24px;
  font-family: Helvetica Neue, sans-serif;
  font-size: 24px;
  font-weight: 500;
`

export const BlockTitle = styled.h3`
  color: ${COLORS.MAIN_TEXT_BLUE};
  margin-bottom: 24px;
  line-height: 1;
  font-family: HelveticaNeue, sans-serif;
  font-weight: 700;
  font-size: 18px;
`

export const RadioButtonBLock = styled.div`
  margin-bottom: 36px;
  display: flex;
  flex-direction: row;
`

export const InputsBlock = styled.div<{ noBorder?: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border-bottom: ${({ noBorder }) =>
    noBorder ? 'none' : `1px solid ${COLORS.GRAY}`};
  margin-bottom: 20px;
`

export const InputContainer = styled.div`
  margin-bottom: 20px;
  &:not(:last-child) {
    margin-right: 10px;
  }
`

export const ModalTitle = styled.div`
  color: ${COLORS.MAIN_TEXT_BLUE};
  font-family: Helvetica Neue, sans-serif;
  font-weight: bold;
  font-size: 18px;
`
