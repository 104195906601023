import axios from 'common/api/api'
import { Student } from 'common/types/students'
import {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react'

import { useHomePageStore } from '../../../pages/HomePage/store/store'
import {
  StudentsContextProps,
  StudentsResponse,
} from './StudentsProvider.types'

export const StudentsContext = createContext<StudentsContextProps>({
  students: [],
})

const formatPhoneNumberUS = (phoneNumber: string) => {
  const digitsOnly = phoneNumber.replace(/\D/g, '')

  if (!digitsOnly) {
    return ''
  }

  const countryCode = '1'
  if (digitsOnly.startsWith(countryCode)) {
    return digitsOnly
  }

  return countryCode.concat(digitsOnly)
}

const newResponseData = (data: Student[]) => {
  return data.map(student => {
    return {
      ...student,
      firstGuardianPhone:
        student.firstGuardianPhone &&
        formatPhoneNumberUS(student.firstGuardianPhone.toString()),
      firstGuardianWorkPhone:
        student.firstGuardianWorkPhone &&
        formatPhoneNumberUS(student.firstGuardianWorkPhone.toString()),
      secondGuardianPhone:
        student.secondGuardianPhone &&
        formatPhoneNumberUS(student.secondGuardianPhone.toString()),
      secondGuardianWorkPhone:
        student.secondGuardianWorkPhone &&
        formatPhoneNumberUS(student.secondGuardianWorkPhone.toString()),
      emergencyContactPhone:
        student.emergencyContactPhone &&
        formatPhoneNumberUS(student.emergencyContactPhone.toString()),
      emergencyContactWorkPhone:
        student.emergencyContactWorkPhone &&
        formatPhoneNumberUS(student.emergencyContactWorkPhone.toString()),
    }
  })
}

export const StudentsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [students, setStudents] = useState<Student[]>([])

  const fetchStudentsList = async () => {
    useHomePageStore.getState().setStore({ isLoadingStudents: true })

    try {
      const response = await axios.get<StudentsResponse>(`riders/index`)
      if (response?.data) {
        setStudents(newResponseData(response.data))
        useHomePageStore.getState().setStore({ students: response.data })
      }
    } catch (e) {
      //
    } finally {
      useHomePageStore.getState().setStore({ isLoadingStudents: false })
    }
  }

  useEffect(() => {
    void fetchStudentsList()
  }, [])

  return (
    <StudentsContext.Provider value={{ students, fetchStudentsList }}>
      {children}
    </StudentsContext.Provider>
  )
}
