import { COLORS } from 'common/const/colors.const'
import styled from 'styled-components'

export const InputWrapper = styled.div<{ width?: number }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => `${width}px`};
`

export const Input = styled.input<{ errorBorder?: boolean }>`
  padding: 10px;
  border: ${({ errorBorder }) =>
    errorBorder ? `solid 1px ${COLORS.RED}` : `solid 1px ${COLORS.GRAY}`};
  border-radius: 2px;
  font-family: HelveticaNeue, sans-serif;
  outline: none;
  font-size: 14px;
  width: 100%;
  max-height: 39px;
`

export const Label = styled.label`
  font-family: HelveticaNeue, sans-serif;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 2px;
`

export const AlertError = styled.span`
  margin-top: 5px;
  display: block;
  flex: content-box;
  color: ${COLORS.RED};
  width: 100%;
  height: 2px;
  font-family: HelveticaNeue, sans-serif;
  font-size: 12px;
`
