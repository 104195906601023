import { Header } from 'components/Header'
import React, { FC, PropsWithChildren } from 'react'

import { PagesContainer, PagesWrapper } from './Layout.styled'
import { Props } from './Layout.types'

export const Layout: FC<PropsWithChildren<Props>> = ({
  children,
  isNavDisabled,
  isAddressNavDisabled,
}) => {
  return (
    <>
      <Header
        isNavDisabled={isNavDisabled}
        isAddressNavDisabled={isAddressNavDisabled}
      />
      <PagesWrapper>
        <PagesContainer>{children}</PagesContainer>
      </PagesWrapper>
    </>
  )
}
