import { COLORS } from 'common/const/colors.const'
import React from 'react'

import { StyledButton } from './Button.styled'
import { ButtonProps } from './Button.types'

export const Button: React.FC<ButtonProps> = ({
  children,
  name,
  style,
  onClick,
  fontSize = '16px',
  type = 'button',
  width = '114px',
  height = '34px',
  background = COLORS.ORANGE,
  border = COLORS.ORANGE,
  color = COLORS.WHITE,
  hoverBorder = COLORS.ORANGE,
  hoverColor = COLORS.ORANGE,
  hoverBackground = 'transparent',
  disabled,
  disabledBackground = COLORS.ORANGE,
}) => {
  return (
    <StyledButton
      disabledBackground={disabledBackground}
      width={width}
      height={height}
      hoverBorder={hoverBorder}
      hoverColor={hoverColor}
      hoverBackground={hoverBackground}
      color={color}
      background={background}
      border={border}
      onClick={onClick}
      style={style}
      type={type}
      disabled={disabled}
      fontSize={fontSize}
    >
      {name}
      {children}
    </StyledButton>
  )
}
