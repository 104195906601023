import { FormContext } from 'common/context/FormProvider'
import { useContext } from 'react'

export const useFormContext = () => {
  const { addressesState, generalInfoState, notesState } =
    useContext(FormContext)

  return {
    addressesState,
    generalInfoState,
    notesState,
  }
}
