import axios from 'axios'
import { TOKEN } from 'common/const/navigation.consts'
import { getStorage, removeStorage } from 'common/services/storage.service'

import { AxiosErrorExtended } from './api.types'

const baseURL =
  process.env.REACT_APP_API_URL || 'https://parent.dev.schoolbusmanager.com/'

const axiosInstance = axios.create({
  baseURL: baseURL + 'api/portal/',
})

axiosInstance.interceptors.request.use(request => {
  request.headers = {
    ...request.headers,
  }
  const token = getStorage(TOKEN)

  if (token) {
    request.headers.Authorization = `Bearer ${token}`
  }
  return request
})

axiosInstance.interceptors.response.use(
  response => response,
  (error: AxiosErrorExtended) => {
    if (error.response?.status === 401) {
      removeStorage(TOKEN)
    }
    if (error.response?.status === 403) {
      console.error('Something went wrong', error.response)
    }

    return Promise.reject(error.response)
  }
)

export default axiosInstance
