// TODO remove ts-ignore comments
import { notification } from 'antd'
import axios from 'common/api/api'
import {
  CLIENT,
  HOME_PAGE,
  PICK_UP,
  REQUESTS,
} from 'common/const/navigation.consts'
import {
  generalInfoValidationSchema,
  initialInfoValues,
} from 'common/const/validation/generalInfo'
import { useStudentsContext } from 'common/hooks/useStudentsContext'
import { ModalTitle } from 'common/styles/common.styled'
import {
  CreateRequestType,
  RequestChange,
  RequestField,
} from 'common/types/request'
import { Student } from 'common/types/students'
import { ActionButtons } from 'components/ActionButtons'
import { ModalWindow } from 'components/ModalWindow'
import { useFormik } from 'formik'
import { ModalContainer, ModalText } from 'pages/GeneralInfo/GeneralInfo.styled'
import { Notes, NotesResponse } from 'pages/NotesToTransportation'
import { Address, AddressesResponse, AddressGeo } from 'pages/PickUp'
import React, {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { FormContextProps } from './FormProvider.types'

export const FormContext = createContext<FormContextProps>({
  addressesState: {
    incorrectPickUpAddress: false,
    incorrectDropOffAddress: false,
    addressType: 1,
    addresses: [],
    pickUpValue: '',
    dropOffValue: '',
    primaryPickUpAddress: undefined,
    primaryDropOffAddress: undefined,
    handleChangeAddressType: () => {},
    handlePickUpChange: () => {},
    handleDropOffChange: () => {},
  },
  generalInfoState: {
    values: initialInfoValues,
    errors: {},
    touched: {},
    handleBlur: () => {},
    handleChange: () => {},
    setFieldValue: () => {},
  },
  notesState: {
    notes: [],
    notesCurrentValue: '',
    setNotesCurrentValue: () => {},
  },
})

export const FormProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)

  const { id = '' } = useParams()
  const { students } = useStudentsContext()
  const navigate = useNavigate()

  const [pickUpValue, setPickUpValue] = useState('')
  const [dropOffValue, setDropOffValue] = useState('')
  const [addresses, setAddresses] = useState<Address[]>([])

  const [generalInfoActionButtons, setGeneralInfoActionButtons] =
    useState(false)

  const [notesActionButtons, setNotesActionButtons] = useState(false)
  const [notes, setNotes] = useState<Notes[]>([])
  const [notesCurrentValue, setNotesCurrentValue] = useState<string>('')

  const [addressTypeChanged, setAddressTypeChanged] = useState(false)

  const [saveModal, setSaveModal] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [exitModal, setExitModal] = useState(false)

  const [addressType, setAddressType] = useState<number>(1)

  const [primaryPickUpAddress, setPrimaryPickUpAddress] = useState<
    Address | undefined
  >()
  const [primaryDropOffAddress, setPrimaryDropOffAddress] = useState<
    Address | undefined
  >()

  const [incorrectPickUpAddress, setIncorrectPickUpAddress] = useState(false)
  const [incorrectDropOffAddress, setIncorrectDropOffAddress] = useState(false)

  const requestData: CreateRequestType = {
    riderId: Number(id),
    changes: [],
  }

  const findStudent = (student: Student) => {
    return student.id === Number(id)
  }

  const student: Student | undefined = students.find(findStudent)

  const filterAddresses = (addresses: Address[], type: number) => {
    return addresses.filter(item => item.type === type)
  }

  const getAddresses = async () => {
    try {
      const response = await axios.get<AddressesResponse>(
        `riders/addresses/${id}`
      )
      const data = response?.data

      if (data) {
        setAddresses(data)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const setInitialStates = () => {
    if (primaryDropOffAddress) {
      setDropOffValue(primaryDropOffAddress?.address)
    } else setDropOffValue('')
    if (primaryPickUpAddress) {
      setPickUpValue(primaryPickUpAddress?.address)
    } else setPickUpValue('')
  }

  const prepareFields = (value: string, type: number): RequestField => {
    if (type === 1) {
      return {
        pickupAddress: value,
      } as RequestField
    }
    return {
      dropoffAddress: value,
    } as RequestField
  }

  const prepareChanges = (
    addressType: number,
    type: string,
    oldValue?: string,
    newValue?: string
  ) => {
    const change: Partial<RequestChange> = {
      type,
      entityType: 'address',
    }
    if (typeof oldValue === 'string') {
      change.oldFields = prepareFields(oldValue, addressType)
    }
    if (typeof newValue === 'string') {
      change.newFields = prepareFields(newValue, addressType)
    }

    return change
  }

  const isPickUpChanged = primaryPickUpAddress
    ? pickUpValue !== primaryPickUpAddress?.address
    : !!pickUpValue?.length
  const isDropOffChanged = primaryDropOffAddress
    ? dropOffValue !== primaryDropOffAddress?.address
    : !!dropOffValue?.length

  const isDeleteDropOff = !!(addressType === 1 && primaryDropOffAddress)

  const isDeletePickUp = !!(addressType === 2 && primaryPickUpAddress)

  const handleSubmitAddresses = () => {
    if (addressType === 1) {
      if (isPickUpChanged || !primaryPickUpAddress) {
        requestData.changes?.push({
          ...(prepareChanges(
            1,
            primaryPickUpAddress ? 'update' : 'create',
            primaryPickUpAddress?.address,
            pickUpValue
          ) as RequestChange),
          ...(primaryPickUpAddress && { entityId: primaryPickUpAddress.id }),
        })
      }
      if (primaryDropOffAddress) {
        requestData.changes?.push({
          ...(prepareChanges(
            2,
            'delete',
            primaryDropOffAddress?.address
          ) as RequestChange),
          entityId: primaryDropOffAddress.id,
        })
      }
    } else if (addressType === 2) {
      if (isDropOffChanged || !primaryDropOffAddress) {
        requestData.changes?.push({
          ...(prepareChanges(
            2,
            primaryDropOffAddress ? 'update' : 'create',
            primaryDropOffAddress?.address,
            dropOffValue
          ) as RequestChange),
          ...(primaryDropOffAddress && { entityId: primaryDropOffAddress.id }),
        })
      }
      if (primaryPickUpAddress) {
        requestData.changes?.push({
          ...(prepareChanges(
            1,
            'delete',
            primaryPickUpAddress?.address
          ) as RequestChange),
          entityId: primaryPickUpAddress.id,
        })
      }
    } else {
      if (isPickUpChanged || !primaryPickUpAddress) {
        requestData.changes?.push({
          ...(prepareChanges(
            1,
            primaryPickUpAddress ? 'update' : 'create',
            primaryPickUpAddress?.address,
            pickUpValue
          ) as RequestChange),
          ...(primaryPickUpAddress && { entityId: primaryPickUpAddress.id }),
        })
      }
      if (isDropOffChanged || !primaryDropOffAddress) {
        requestData.changes?.push({
          ...(prepareChanges(
            2,
            primaryDropOffAddress ? 'update' : 'create',
            primaryDropOffAddress?.address,
            dropOffValue
          ) as RequestChange),
          ...(primaryDropOffAddress && { entityId: primaryDropOffAddress.id }),
        })
      }
    }
  }

  const validateAddresses = async () => {
    let isValid = true
    try {
      if (addressType === 1) {
        if (pickUpValue) {
          const response = await axios.get<AddressGeo>(
            `/user/geocode?address=${pickUpValue}`
          )
          const data: AddressGeo = response?.data
          if (!data.lat && !data.lon) {
            setIncorrectPickUpAddress(true)
            isValid = false
            setSaveModal(false)
          } else {
            setSaveModal(false)
            handleSubmitAddresses()
          }
        } else {
          setIncorrectPickUpAddress(true)
          isValid = false
          setSaveModal(false)
        }
      }
      if (addressType === 2) {
        if (dropOffValue) {
          const response = await axios.get<AddressGeo>(
            `/user/geocode?address=${dropOffValue}`
          )
          const data: AddressGeo = response?.data
          if (!data.lat && !data.lon) {
            setIncorrectDropOffAddress(true)
            isValid = false
            setSaveModal(false)
          } else {
            setSaveModal(false)
            handleSubmitAddresses()
          }
        } else {
          setIncorrectDropOffAddress(true)
          isValid = false
          setSaveModal(false)
        }
      }
      if (addressType === 3) {
        if (pickUpValue && dropOffValue) {
          if (isPickUpChanged) {
            const { data } = await axios.get<AddressGeo>(
              `/user/geocode?address=${pickUpValue}`
            )
            if (!data.lat && !data.lon) {
              setIncorrectPickUpAddress(true)
              isValid = false
            }
          }
          if (isDropOffChanged) {
            const { data: dataDropOff } = await axios.get<AddressGeo>(
              `/user/geocode?address=${dropOffValue}`
            )
            if (!dataDropOff.lat && !dataDropOff.lon) {
              setIncorrectDropOffAddress(true)
              isValid = false
            }
          }

          if (isValid) {
            handleSubmitAddresses()
          }
          setSaveModal(false)
        } else {
          if (!pickUpValue) {
            setIncorrectPickUpAddress(true)
            isValid = false
            setSaveModal(false)
          }
          if (!dropOffValue) {
            setIncorrectDropOffAddress(true)
            isValid = false
            setSaveModal(false)
          }
        }
      }
    } catch (e) {
      console.error(e)
    }

    return isValid
  }

  useEffect(() => {
    const newPrimaryPickUpAddress: Address =
      addresses && filterAddresses(addresses, 1)[0]
    const newPrimaryDropOffAddress: Address =
      addresses && filterAddresses(addresses, 2)[0]
    const isBoth = newPrimaryPickUpAddress && newPrimaryDropOffAddress

    if (newPrimaryPickUpAddress || newPrimaryDropOffAddress) {
      setAddressType(isBoth ? 3 : newPrimaryPickUpAddress ? 1 : 2)

      if (newPrimaryPickUpAddress) {
        setPrimaryPickUpAddress(newPrimaryPickUpAddress)
        setPickUpValue(newPrimaryPickUpAddress.address)
      }
      if (newPrimaryDropOffAddress) {
        setPrimaryDropOffAddress(newPrimaryDropOffAddress)
        setDropOffValue(newPrimaryDropOffAddress.address)
      }
    }
  }, [addresses])

  const handleChangeAddressType = (addressType: number) => {
    setAddressType(addressType)
    setAddressTypeChanged(true)
    setIncorrectDropOffAddress(false)
    setIncorrectPickUpAddress(false)
  }

  const handlePickUpChange = (value: string) => {
    setPickUpValue(value)
    setIncorrectPickUpAddress(false)
  }

  const handleDropOffChange = (value: string) => {
    setDropOffValue(value)
    setIncorrectDropOffAddress(false)
  }

  useEffect(() => {
    if (id) {
      void getAddresses()
    }
  }, [id])

  // General Info

  const getStudentDataValues = (student: Student | undefined) => {
    const initialStudent = { ...initialInfoValues }
    if (student) {
      Object.keys(student).forEach(key => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        if (student[key] || student[key] === false) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          initialStudent[key] = student[key]
        }
      })
    }
    return initialStudent
  }

  const currentStudentState = getStudentDataValues(student)

  const handleCreateRequest = (changedValues: Partial<Student>) => {
    const changedValuesKeys = Object.keys(changedValues)
    const oldFields: RequestField = {} as RequestField
    const newFields: RequestField = {} as RequestField

    changedValuesKeys.forEach(key => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      newFields[key] = changedValues[key]
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      oldFields[key] = currentStudentState[key]
    })

    requestData.changes.push({
      type: 'update',
      entityType: 'child',
      entityId: Number(id),
      oldFields,
      newFields,
    })
  }

  const prepareChangedFields = (currentValues: Student) => {
    const changedValues: Partial<Student> = {}

    const currentStateKeys = Object.keys(currentStudentState) as Array<
      keyof typeof currentStudentState
    >

    currentStateKeys.forEach(key => {
      if (currentStudentState[key] !== currentValues[key]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        changedValues[key] = currentValues[key] as string
      }
    })

    return changedValues
  }

  const handleSubmitStudent = (values: Student) => {
    const changedValues = prepareChangedFields(values)

    if (Object.keys(changedValues)?.length) {
      try {
        handleCreateRequest(changedValues)
      } catch (e) {
        console.error(e)
      } finally {
        setSaveModal(false)
        setGeneralInfoActionButtons(false)
      }
    }
  }

  const {
    resetForm,
    handleSubmit,
    values,
    errors,
    handleBlur,
    handleChange,
    isValid: isGeneralInfoValid,
    setFieldValue,
    touched,
  } = useFormik({
    initialValues: currentStudentState,
    onSubmit: handleSubmitStudent,
    enableReinitialize: true,
    validationSchema: generalInfoValidationSchema,
  })

  useEffect(() => {
    const changedValues = prepareChangedFields(values)
    setGeneralInfoActionButtons(!!Object.keys(changedValues).length)
  }, [values])

  // Notes to transportation

  const getNotes = async () => {
    try {
      const response = await axios.get<NotesResponse>(`riders/notes/${id}`)
      const data = response?.data

      if (data) {
        setNotes(data)
      }
    } catch (e) {
      console.error(e)
    }
  }

  if (notesCurrentValue) {
    requestData.changes.push({
      type: 'create',
      entityType: 'note',
      newFields: {
        noteText: notesCurrentValue,
      } as RequestField,
    } as RequestChange)
  }

  useEffect(() => {
    if (id) {
      void getNotes()
    }
  }, [id])

  useEffect(() => {
    setNotesActionButtons(Boolean(notesCurrentValue))
  }, [notesCurrentValue])

  // Action Buttons

  const handleCancel = () => {
    resetForm()
    setNotesCurrentValue('')
    setInitialStates()
    setAddressTypeChanged(false)
    setCancelModal(false)
  }

  const handleExit = () => {
    resetForm()
    setNotesCurrentValue('')
    setInitialStates()
    setExitModal(false)
    navigate(HOME_PAGE)
  }

  const createRequest = async () => {
    try {
      setIsLoading(true)
      if (requestData.changes.length) {
        await axios.post<CreateRequestType>(`requests/create`, requestData)
      }
      handleSubmit()
      navigate(REQUESTS)
      notification.success({
        message:
          'Your request has been submitted. Please allow up to 48 hours to be processed.',
      })
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
      setSaveModal(false)
      setNotesCurrentValue('')
      setGeneralInfoActionButtons(false)
      setAddressTypeChanged(false)
    }
  }

  const handleSubmitAll = async () => {
    setIsLoading(true)
    let isValid = true
    if (
      isPickUpChanged ||
      isDropOffChanged ||
      isDeleteDropOff ||
      isDeletePickUp ||
      addressTypeChanged
    ) {
      isValid = await validateAddresses()
      setInitialStates()
    }

    if (isValid) {
      if (isValid) {
        if (isGeneralInfoValid) {
          handleSubmitStudent(values)
          await createRequest()
        } else {
          setSaveModal(false)
          setGeneralInfoActionButtons(false)
        }
      } else navigate(`${CLIENT}/${id}${PICK_UP}`)
    }
  }

  return (
    <FormContext.Provider
      value={{
        addressesState: {
          incorrectPickUpAddress,
          incorrectDropOffAddress,
          addressType,
          addresses,
          pickUpValue,
          dropOffValue,
          primaryPickUpAddress,
          primaryDropOffAddress,
          handlePickUpChange,
          handleDropOffChange,
          handleChangeAddressType,
        },
        generalInfoState: {
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          values,
        },
        notesState: {
          notes,
          notesCurrentValue,
          setNotesCurrentValue,
        },
      }}
    >
      {children}

      <ActionButtons
        visible={
          isPickUpChanged ||
          isDropOffChanged ||
          isDeleteDropOff ||
          isDeletePickUp ||
          addressTypeChanged ||
          generalInfoActionButtons ||
          notesActionButtons
        }
        onCancel={() => setCancelModal(true)}
        onExit={() => setExitModal(true)}
        onSave={() => setSaveModal(true)}
      />

      <ModalWindow
        width={372}
        visible={saveModal}
        mgTop={95}
        disabled={isLoading}
        onCloseClick={() => setSaveModal(false)}
        onNoClick={() => setSaveModal(false)}
        onYesClick={handleSubmitAll}
      >
        <ModalContainer>
          <ModalTitle>Save</ModalTitle>
          <ModalText>
            You are about to update the existing information are you sure?
          </ModalText>
        </ModalContainer>
      </ModalWindow>

      <ModalWindow
        width={372}
        mgTop={95}
        visible={cancelModal}
        onCloseClick={() => setCancelModal(false)}
        onNoClick={() => setCancelModal(false)}
        onYesClick={() => {
          handleCancel()
          setIncorrectPickUpAddress(false)
          setIncorrectDropOffAddress(false)
        }}
      >
        <ModalContainer>
          <ModalTitle>Cancel</ModalTitle>
          <ModalText>All your changes will be lost, are you sure?</ModalText>
        </ModalContainer>
      </ModalWindow>

      <ModalWindow
        width={372}
        mgTop={95}
        visible={exitModal}
        onCloseClick={() => setExitModal(false)}
        onNoClick={() => setExitModal(false)}
        onYesClick={handleExit}
      >
        <ModalContainer>
          <ModalTitle>Exit</ModalTitle>
          <ModalText>
            You have changes that have not been saved. Are you sure you want to
            Exit?
          </ModalText>
        </ModalContainer>
      </ModalWindow>
    </FormContext.Provider>
  )
}
