export const COLORS = {
  ORANGE: '#f87a03',
  BLACK: '#000000',
  GRAY_BLACK: '#333333',
  GRAY_WHITE: '#9a9a9a',
  DISABLED: '#8a8a8a',
  RED: '#ff4343',
  GRAY: '#cdcbcd',
  WHITE: '#ffffff',
  LIGHT_BLUE: '#3cb0e7',
  LIGHT_BLUE_DISABLED: '#9bdfff',
  DARK_BLUE: '#1e334d',
  MAIN_TEXT_BLUE: '#40689f',
  BUTTON_GRAY: '#a6a6a6',
  GRAY_TEXT: '#868686',
  LIGHT_GRAY: '#f8f8f8',
  STATUS_GREEN: '#26c281',
  STATUS_RED: '#d0021b',
  HINT_TEXT_GREEN: 'rgb(95,193,118)',
  PLACEHOLDER_GREY: 'rgb(0,0,0,.32)',
}
