import { RequestFieldKeys } from '../types/request'

export const requestStatuses = {
  1: 'New',
  2: 'In Review',
  3: 'Accepted',
  4: 'Rejected',
}

export const requestAddresses = {
  1: 'Primary Pickup Address',
  2: 'Primary Drop Off Address',
}

export const requestFields = {
  [RequestFieldKeys.firstGuardianName]: 'First Guardian Name',
  [RequestFieldKeys.firstGuardianPhone]: 'First Guardian Phone',
  [RequestFieldKeys.firstGuardianWorkPhone]: 'First Guardian Work Phone',
  [RequestFieldKeys.firstGuardianEmail]: 'First Guardian Email',
  [RequestFieldKeys.secondGuardianName]: 'Second Guardian Name',
  [RequestFieldKeys.secondGuardianPhone]: 'Second Guardian Phone',
  [RequestFieldKeys.secondGuardianWorkPhone]: 'Second Guardian Work Phone',
  [RequestFieldKeys.secondGuardianEmail]: 'Second Guardian Email',
  [RequestFieldKeys.emergencyContactName]: 'Emergency Contact Name',
  [RequestFieldKeys.emergencyContactPhone]: 'Emergency Contact Phone',
  [RequestFieldKeys.emergencyContactWorkPhone]: 'Emergency Contact Work Phone',
  [RequestFieldKeys.emergencyContactEmail]: 'Emergency Contact Email',
  [RequestFieldKeys.alertNote]: 'Special needs',
  [RequestFieldKeys.noteText]: 'Note to transportation',
  [RequestFieldKeys.schoolName]: 'School Name',
  [RequestFieldKeys.address]: 'Address',
  [RequestFieldKeys.fullName]: 'Full Name',
  [RequestFieldKeys.type]: 'Type',
  [RequestFieldKeys.pickupAddress]: 'Pickup / Morning Home Address',
  [RequestFieldKeys.dropoffAddress]: 'Drop Off / Afternoon Address',
  [RequestFieldKeys.isRiding]: 'Is Riding',
}
