import { useContext } from 'react'

import { RegistrationContext } from '../context/RegistrationProvider'

export const useRegistrationContext = () => {
  const {
    students,
    guardianInfo,
    handleChange,
    setStatus,
    setStudents,
    setAddresses,
    setGuardianInformation,
    setGuardianContacts,
    setAddressesType,
    setAddressesEditing,
  } = useContext(RegistrationContext)

  return {
    students,
    guardianInfo,
    handleChange,
    setStudents,
    setStatus,
    setAddresses,
    setGuardianInformation,
    setGuardianContacts,
    setAddressesType,
    setAddressesEditing,
  }
}
