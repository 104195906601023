import {
  GENERAL_INFO,
  GUARDIAN_INFO,
  HOME_PAGE,
  LOGIN,
  LOGIN_COMPANY,
  NOTES_TO_TRANSPORTATION,
  PICK_UP_PAGE,
  REGISTRATION,
  REGISTRATION_ADDRESSES,
  REQUESTS,
} from 'common/const/navigation.consts'
import { GlobalStyle } from 'common/styles/globalStyles'
import { ProtectedRoute } from 'components/ProtectedRoute'
import { GeneralInfo } from 'pages/GeneralInfo'
import { GuardianInfo } from 'pages/GuardianInfo'
import { PickUp } from 'pages/PickUp/PickUp'
import { RegistrationAddresses } from 'pages/RegistrationAddresses'
import { RegistrationForm } from 'pages/RegistrationForm'
import React, { Fragment, lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { RegistrationProvider } from './common/context/RegistrationProvider'
import { NotesToTransportation } from './pages/NotesToTransportation'
import { Requests } from './pages/Requests'

const LoginPage = lazy(() => import('./pages/LoginPage/LoginPage'))
const HomePage = lazy(() => import('./pages/HomePage/HomePage'))

const App = () => {
  return (
    <>
      <GlobalStyle />
      <Routes>
        <Route path='/' element={<Navigate to={HOME_PAGE} />} />
        <Route
          path={LOGIN}
          element={
            <Suspense fallback={<Fragment></Fragment>}>
              <LoginPage />
            </Suspense>
          }
        />

        <Route
          path={LOGIN_COMPANY}
          element={
            <Suspense fallback={<Fragment></Fragment>}>
              <LoginPage />
            </Suspense>
          }
        />
        <Route
          path={REGISTRATION}
          element={
            <RegistrationProvider>
              <RegistrationForm />
            </RegistrationProvider>
          }
        />
        <Route
          path={REGISTRATION_ADDRESSES}
          element={
            <RegistrationProvider>
              <RegistrationAddresses />
            </RegistrationProvider>
          }
        />
        <Route
          path={GUARDIAN_INFO}
          element={
            <RegistrationProvider>
              <GuardianInfo />
            </RegistrationProvider>
          }
        />
        <Route
          path={HOME_PAGE}
          element={
            <ProtectedRoute>
              <Suspense fallback={<Fragment></Fragment>}>
                <HomePage />
              </Suspense>
            </ProtectedRoute>
          }
        />
        <Route
          path={GENERAL_INFO}
          element={
            <ProtectedRoute>
              <GeneralInfo />
            </ProtectedRoute>
          }
        />
        <Route
          path={PICK_UP_PAGE}
          element={
            <ProtectedRoute>
              <PickUp />
            </ProtectedRoute>
          }
        />
        <Route
          path={NOTES_TO_TRANSPORTATION}
          element={
            <ProtectedRoute>
              <NotesToTransportation />
            </ProtectedRoute>
          }
        />
        <Route
          path={REQUESTS}
          element={
            <ProtectedRoute>
              <Requests />
            </ProtectedRoute>
          }
        />
        <Route
          path='*'
          element={
            <div>
              <h2>404 Page not found</h2>
            </div>
          }
        />
      </Routes>
    </>
  )
}

export default App
