import './index.css'
import 'antd/dist/antd.css'

import { Col, Divider, Row } from 'antd'
import axios from 'common/api/api'
import { COLORS } from 'common/const/colors.const'
import { LOGIN_COMPANY_NO_TOKEN } from 'common/const/navigation.consts'
import { useRegistrationContext } from 'common/hooks/useRegistrationContext'
import { MainWrapper, ModalTitle, Title } from 'common/styles/common.styled'
import { Button } from 'components/Button'
import { InputWithLabel } from 'components/InputWithLabel'
import { LeftRightButtons } from 'components/LeftRightButtons'
import { LayoutRegistration } from 'layouts/Layout'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ModalWindow } from '../../components/ModalWindow'
import { ModalContainer, ModalText } from '../GeneralInfo/GeneralInfo.styled'
import { HintText } from './GuardianInfo.styled'
import { ChildrenData, GuardianInfoRequestData } from './GuardianInfo.types'

export const GuardianInfo = () => {
  const { token } = useParams()
  const {
    students,
    guardianInfo,
    setGuardianInformation,
    setGuardianContacts,
  } = useRegistrationContext()

  const [firstGuardianNameError, setFirstGuardianNameError] = useState('')
  const [firstGuardianPhoneError, setFirstGuardianPhoneError] = useState('')
  const [firstGuardianEmailError, setFirstGuardianEmailError] = useState('')
  const [cancelModal, setCancelModal] = useState(false)
  const [submitModal, setSetSubmitModal] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)

  const navigate = useNavigate()

  // prev student is the last student page with non-empty cid
  let prevStudentCid = ''
  students.forEach(student => {
    if (student.cid) {
      prevStudentCid = student.cid
    }
  })

  const prevPage = `/client/addresses/${token}/${prevStudentCid}`

  const isStudentData = students?.find(
    student => student.cid && student.birthdate
  )

  useEffect(() => {
    if (!isStudentData) navigate(`${LOGIN_COMPANY_NO_TOKEN}/${token}`)
  }, [])

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      e.preventDefault()
      e.returnValue = ''
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  const handleQuit = () => {
    navigate(`${LOGIN_COMPANY_NO_TOKEN}/${token}`)
  }
  const sendForm = async (data: GuardianInfoRequestData) => {
    await axios.post(`/company/registration/${token}`, data)
    setSetSubmitModal(true)
  }
  const preparePayload = () => {
    //children info
    const children: ChildrenData[] = []
    students.forEach(({ cid, birthdate, addresses, addressesType }) => {
      if (cid) {
        addresses.forEach(({ address, type, school }) => {
          if (addressesType === 3 || addressesType === type) {
            children.push({
              cid: cid,
              birthdate: birthdate,
              address: address,
              type: type,
              schoolName: school,
            })
          }
        })
      }
    })
    //strip non-digit characters
    const firstGuardianPhone = guardianInfo.firstGuardianPhone.replace(
        /\D/g,
        ''
      ),
      secondGuardianPhone = guardianInfo.secondGuardianPhone.replace(/\D/g, ''),
      emergencyContactPhone = guardianInfo.emergencyContactPhone.replace(
        /\D/g,
        ''
      ),
      firstGuardianWorkPhone = guardianInfo.firstGuardianWorkPhone.replace(
        /\D/g,
        ''
      ),
      secondGuardianWorkPhone = guardianInfo.secondGuardianWorkPhone.replace(
        /\D/g,

        ''
      ),
      emergencyContactWorkPhone =
        guardianInfo.emergencyContactWorkPhone.replace(/\D/g, '')

    return {
      children: children,
      firstGuardian: guardianInfo.firstGuardianName,
      firstGuardianPhone: firstGuardianPhone,
      firstGuardianWorkPhone: firstGuardianWorkPhone,
      firstGuardianEmail: guardianInfo.firstGuardianEmail,
      secondGuardian: guardianInfo.secondGuardianName,
      secondGuardianPhone: secondGuardianPhone,
      secondGuardianWorkPhone: secondGuardianWorkPhone,
      secondGuardianEmail: guardianInfo.secondGuardianEmail,
      emergencyContact: guardianInfo.emergencyContactName,
      emergencyContactPhone: emergencyContactPhone,
      emergencyContactWorkPhone: emergencyContactWorkPhone,
      emergencyContactEmail: guardianInfo.emergencyContactEmail,
      message: '',
    }
  }
  const submitForm = async () => {
    const firstGuardianNameError = guardianInfo.firstGuardianName
      ? ''
      : 'Required'
    const firstGuardianPhoneError =
      guardianInfo.firstGuardianPhone.length > 9 ? '' : 'Required'
    const firstGuardianEmailError = guardianInfo.firstGuardianEmail
      ? ''
      : 'Required'

    setFirstGuardianNameError(firstGuardianNameError)
    setFirstGuardianPhoneError(firstGuardianPhoneError)
    setFirstGuardianEmailError(firstGuardianEmailError)

    if (
      !firstGuardianNameError &&
      !firstGuardianPhoneError &&
      !firstGuardianEmailError
    ) {
      const payload = preparePayload()
      await sendForm(payload)
    }
  }

  return (
    <>
      <LayoutRegistration>
        <MainWrapper>
          <LeftRightButtons left={`${prevPage}`}>
            <Title>Parent/Guardian information</Title>
            <HintText>
              To receive text messages mobile phone is required.
            </HintText>
            <Row>
              <Col span={6}>
                <InputWithLabel
                  name={'firstGuardianName'}
                  label={'First Guardian'}
                  value={guardianInfo.firstGuardianName}
                  onChange={e => setGuardianInformation(e, 'firstGuardianName')}
                  errorAlert={firstGuardianNameError}
                  errorBorder={!!firstGuardianNameError}
                  required={true}
                />
              </Col>
              <Col span={6}>
                <InputWithLabel
                  name={'firstGuardianPhone'}
                  label={'Cellphone'}
                  value={guardianInfo.firstGuardianPhone}
                  phoneNumber={true}
                  errorAlert={firstGuardianPhoneError}
                  errorBorder={!!firstGuardianPhoneError}
                  onCustomChange={value =>
                    setGuardianContacts(value, 'firstGuardianPhone')
                  }
                  required={true}
                />
              </Col>
              <Col span={6}>
                <InputWithLabel
                  name={'firstGuardianWorkPhone'}
                  label={'Work Phone'}
                  value={guardianInfo.firstGuardianWorkPhone}
                  onCustomChange={name =>
                    setGuardianContacts(name, 'firstGuardianWorkPhone')
                  }
                  phoneNumber={true}
                />
              </Col>
              <Col span={6}>
                <InputWithLabel
                  name={'firstGuardianEmail'}
                  label={'Email'}
                  value={guardianInfo.firstGuardianEmail}
                  errorBorder={!!firstGuardianEmailError}
                  errorAlert={firstGuardianEmailError}
                  onChange={e =>
                    setGuardianInformation(e, 'firstGuardianEmail')
                  }
                  required={true}
                />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={6}>
                <InputWithLabel
                  name={'secondGuardianName'}
                  value={guardianInfo.secondGuardianName}
                  onChange={e =>
                    setGuardianInformation(e, 'secondGuardianName')
                  }
                  label={'Second Guardian'}
                />
              </Col>
              <Col span={6}>
                <InputWithLabel
                  name={'secondGuardianPhone'}
                  label={'Cellphone'}
                  value={guardianInfo.secondGuardianPhone}
                  onCustomChange={name =>
                    setGuardianContacts(name, 'secondGuardianPhone')
                  }
                  phoneNumber={true}
                />
              </Col>
              <Col span={6}>
                <InputWithLabel
                  name={'secondGuardianWorkPhone'}
                  label={'Work Phone'}
                  value={guardianInfo.secondGuardianWorkPhone}
                  onCustomChange={name =>
                    setGuardianContacts(name, 'secondGuardianWorkPhone')
                  }
                  phoneNumber={true}
                />
              </Col>
              <Col span={6}>
                <InputWithLabel
                  onChange={e =>
                    setGuardianInformation(e, 'secondGuardianEmail')
                  }
                  name={'secondGuardianEmail'}
                  value={guardianInfo.secondGuardianEmail}
                  label={'Email'}
                />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={6}>
                <InputWithLabel
                  name={'emergencyContactName'}
                  value={guardianInfo.emergencyContactName}
                  onChange={e =>
                    setGuardianInformation(e, 'emergencyContactName')
                  }
                  label={'Emergency Contact'}
                />
              </Col>
              <Col span={6}>
                <InputWithLabel
                  name={'emergencyContactPhone'}
                  value={guardianInfo.emergencyContactPhone}
                  onCustomChange={name =>
                    setGuardianContacts(name, 'emergencyContactPhone')
                  }
                  label={'Cellphone'}
                  phoneNumber={true}
                />
              </Col>
              <Col span={6}>
                <InputWithLabel
                  name={'emergencyContactWorkPhone'}
                  value={guardianInfo.emergencyContactWorkPhone}
                  onCustomChange={name =>
                    setGuardianContacts(name, 'emergencyContactWorkPhone')
                  }
                  label={'Work Phone'}
                  phoneNumber={true}
                />
              </Col>
              <Col span={6}>
                <InputWithLabel
                  onChange={e =>
                    setGuardianInformation(e, 'emergencyContactEmail')
                  }
                  value={guardianInfo.emergencyContactEmail}
                  name={'emergencyContactEmail'}
                  label={'Email'}
                />
              </Col>
            </Row>
            <div>
              <span style={{ color: 'red' }}>*</span> Required
            </div>
            <HintText>
              Account creation may take 24-48 hrs. Once approved you will be
              notified by email or phone.
            </HintText>
            <Row>
              <Col span={4} offset={16}>
                <Button
                  background={`${COLORS.BUTTON_GRAY}`}
                  disabledBackground={`${COLORS.BUTTON_GRAY}`}
                  width='100px'
                  name='Quit'
                  type='submit'
                  onClick={() => setCancelModal(true)}
                />
              </Col>
              <Col span={4}>
                <Button
                  disabledBackground={`${
                    disableSubmit ? COLORS.DISABLED : COLORS.ORANGE
                  }`}
                  width='100px'
                  name='Submit'
                  type='submit'
                  onClick={() => {
                    setDisableSubmit(true)
                    void submitForm()
                  }}
                  disabled={disableSubmit}
                />
              </Col>
            </Row>
          </LeftRightButtons>
        </MainWrapper>
        <ModalWindow
          width={submitModal ? 470 : 372}
          mgTop={95}
          visible={cancelModal || submitModal}
          onCloseClick={() =>
            cancelModal ? setCancelModal(false) : handleQuit
          }
          onNoClick={cancelModal ? () => setCancelModal(false) : undefined}
          onYesClick={cancelModal ? handleQuit : undefined}
          onOkClick={submitModal ? handleQuit : undefined}
        >
          <ModalContainer>
            <ModalTitle>
              {cancelModal ? 'Quit' : 'Registration request sent!'}
            </ModalTitle>
            <ModalText>
              {cancelModal
                ? 'All your changes will be lost, are you sure?'
                : 'Your registration request has been sent! Thank you. Please allow 24-48 hours for the registration to be completed.'}
            </ModalText>
          </ModalContainer>
        </ModalWindow>
      </LayoutRegistration>
    </>
  )
}
