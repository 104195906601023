import React, { FC } from 'react'

import {
  CommentContainer,
  CommentLabel,
  CommentLabelTime,
  CommentText,
  LabelContainer,
} from './CommentBlock.styled'
import { Props } from './CommentBlock.types'

export const CommentBlock: FC<Props> = ({ title, createdTime, text }) => {
  return (
    <CommentContainer>
      <LabelContainer>
        <CommentLabel>{title}</CommentLabel>
        <CommentLabelTime>{createdTime}</CommentLabelTime>
      </LabelContainer>
      <CommentText>{text}</CommentText>
    </CommentContainer>
  )
}
