import * as Yup from 'yup'
import { ObjectShape } from 'yup/lib/object'

export const initialInfoValues = {
  fullName: '',
  birthDate: '',
  firstGuardianName: '',
  firstGuardianPhone: '',
  firstGuardianWorkPhone: '',
  firstGuardianEmail: '',
  secondGuardianName: '',
  secondGuardianPhone: '',
  secondGuardianWorkPhone: '',
  secondGuardianEmail: '',
  emergencyContactEmail: '',
  emergencyContactName: '',
  emergencyContactPhone: '',
  emergencyContactWorkPhone: '',
  isRiding: true,
  noteText: '',
  alertNote: '',
  isUse: false,
}

const phoneNumberTypes = [
  'firstGuardianPhone',
  'firstGuardianWorkPhone',
  'secondGuardianPhone',
  'secondGuardianWorkPhone',
  'emergencyContactPhone',
  'emergencyContactWorkPhone',
]

const emailTypes = [
  'firstGuardianEmail',
  'secondGuardianEmail',
  'emergencyContactEmail',
]

const generalInfoValidationObject: ObjectShape = {}

phoneNumberTypes.forEach(number => {
  generalInfoValidationObject[number] = Yup.string().test(
    'phone length',
    'Enter Valid Phone Number',
    (value: string | undefined) => {
      if (value?.length) {
        return value.length >= 11
      }
      return true
    }
  )
})

emailTypes.forEach(number => {
  generalInfoValidationObject[number] = Yup.string().test(
    'test-name',
    'Enter Valid Email Address',
    (value: string | undefined) => {
      if (value?.length) {
        const emailRegex = /\S+@\S+\.\S+/
        return emailRegex.test(value)
      }
      return true
    }
  )
})

export const generalInfoValidationSchema = Yup.object(
  generalInfoValidationObject
)
