import { createGlobalStyle } from 'styled-components'

import { COLORS } from '../const/colors.const'

export const GlobalStyle = createGlobalStyle`

  * {
    box-sizing: border-box;
  }

  @font-face {
    font-family: Helvetica Neue, sans-serif;
    src: url('./../fonts/helvetica/HelveticaNeueBlackCondensed.eot');
    src: local('./../fonts/helvetica/Helvetica Neue Condensed Black'), local('HelveticaNeueBlackCondensed'),
    url('./../fonts/helvetica/HelveticaNeueBlackCondensed.eot?#iefix') format('embedded-opentype'),
    url('./../fonts/helvetica/HelveticaNeueBlackCondensed.woff2') format('woff2'),
    url('./../fonts/helvetica/HelveticaNeueBlackCondensed.woff') format('woff'),
    url('./../fonts/helvetica/HelveticaNeueBlackCondensed.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: HelveticaNeue-Medium, sans-serif;
    src: url('./../fonts/helvetica/HelveticaNeueCyr-Medium.eot');
    src: local('./../fonts/helvetica/HelveticaNeueCyr-Medium'),
    url('./../fonts/helvetica/HelveticaNeueCyr-Medium.eot?#iefix') format('embedded-opentype'),
    url('./../fonts/helvetica/HelveticaNeueCyr-Medium.woff2') format('woff2'),
    url('./../fonts/helvetica/HelveticaNeueCyr-Medium.woff') format('woff'),
    url('./../fonts/helvetica/HelveticaNeueCyr-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  
  body {
    margin: 0;
    padding: 0;
    background: ${COLORS.WHITE};
  }
`
