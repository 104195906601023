import { COLORS } from 'common/const/colors.const'
import styled from 'styled-components'

export const MainInfoBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`

export const Avatar = styled.div`
  border-radius: 50%;
  width: 82px;
  height: 82px;
  background: ${COLORS.GRAY_BLACK};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`

export const AvatarText = styled.span`
  color: ${COLORS.WHITE};
  font-family: HelveticaNeue, sans-serif;
  font-size: 10px;
  text-align: center;
`

export const InfoItemWrapper = styled.div`
  margin-right: 18px;
  &:last-child {
    margin-right: 0;
  }
`
export const ModalContainer = styled.div`
  font-family: HelveticaNeue, sans-serif;
  text-align: center;
  height: 76px;
`

export const ModalText = styled.div`
  font-family: HelveticaNeue, sans-serif;
  font-size: 16px;
  padding-top: 18px;
`
