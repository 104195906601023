import { COLORS } from 'common/const/colors.const'
import styled from 'styled-components'

export const CommentLabel = styled.label`
  font-family: HelveticaNeue, sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  letter-spacing: -0.7px;
  padding-right: 5px;
  color: ${COLORS.BLACK};
`

export const CommentLabelTime = styled.label`
  font-family: HelveticaNeue, sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.53px;
  color: ${COLORS.GRAY_TEXT};
`
export const CommentContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`

export const CommentText = styled.span`
  word-wrap: break-word;
  font-family: HelveticaNeue, sans-serif;
  font-size: 14px;
  color: #2c2d30;
`
