import { COLORS } from 'common/const/colors.const'
import styled from 'styled-components'

export const InfoBlockWrapper = styled.div`
  margin-bottom: 20px;
`

export const AlertError = styled.span`
  display: block;
  flex: content-box;
  color: ${COLORS.RED};
  width: 176px;
  height: 2px;
  margin: -30px 164px 0 0;
  font-family: HelveticaNeue, sans-serif;
  font-size: 12px;
`
