import styled from 'styled-components'

export const DropDownContainer = styled.div`
  width: 173px;
  position: relative;
`

export const DropDownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: HelveticaNeue, sans-serif;
  font-size: 14px;
  cursor: pointer;
  width: 173px;
  height: 35px;
  padding: 10px 10px 7px;
  border-radius: 2px;
  border: solid 1px #cdcbcd;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  background: #ffffff;
`

export const DropDownListContainer = styled.div`
  position: absolute;
  width: 100%;
  z-index: 2;
`

export const DropDownList = styled.ul`
  cursor: pointer;
  font-family: HelveticaNeue, sans-serif;
  font-size: 14px;
  padding: 0;
  margin: 0;
  padding-left: 1em;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  font-weight: 500;
  &:first-child {
    padding-top: 0.8em;
  }
`

export const ListItem = styled.li`
  list-style: none;
  margin-bottom: 0.8em;
  :hover {
    font-weight: bold;
  }
`

export const ArrowDownContainer = styled.img`
  width: 12px;
  height: 18px;
`
