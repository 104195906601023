import { TOKEN } from 'common/const/navigation.consts'
import { LOGIN } from 'common/const/navigation.consts'
import { FormProvider } from 'common/context/FormProvider'
import { StudentsProvider } from 'common/context/StudentsProvider'
import { getStorage } from 'common/services/storage.service'
import React, { FC, PropsWithChildren } from 'react'
import { Navigate } from 'react-router-dom'

export const ProtectedRoute: FC<PropsWithChildren> = ({ children }) => {
  const token = getStorage(TOKEN)

  if (!token) {
    return <Navigate to={LOGIN} replace />
  }

  return (
    <StudentsProvider>
      <FormProvider>{children}</FormProvider>
    </StudentsProvider>
  )
}
