import './index.css'

import { Col, Row } from 'antd'
import axiosInstance from 'common/api/api'
import { COLORS } from 'common/const/colors.const'
import { LOGIN_COMPANY_NO_TOKEN } from 'common/const/navigation.consts'
import { Student } from 'common/context/RegistrationProvider'
import { useRegistrationContext } from 'common/hooks/useRegistrationContext'
import { MainWrapper, ModalTitle, Title } from 'common/styles/common.styled'
import { Button } from 'components/Button'
import { LeftRightButtons } from 'components/LeftRightButtons'
import { ModalWindow } from 'components/ModalWindow'
import { LayoutRegistration } from 'layouts/Layout'
import React, { useState } from 'react'
import InputMask from 'react-input-mask'
import { useParams } from 'react-router-dom'

import { ModalContainer, ModalText } from '../GeneralInfo/GeneralInfo.styled'
import {
  FlexContainer,
  HintText,
  LeftBlock,
  MessageText,
  RightBlock,
  StudentCID,
  StudentDiv,
  StudentsContainer,
  StyledSpan,
  TitleContainer,
} from './RegistrationForm.styled'

export const RegistrationForm = () => {
  const [errors, setErrors] = useState<number[]>([])

  const { students, handleChange, setStatus } = useRegistrationContext()
  const { token } = useParams()
  const [cancelModal, setCancelModal] = useState(false)
  const [isStudentEditing, setStudentEditing] = useState(false)
  const checkChild = async (student: Student) => {
    try {
      const response = await axiosInstance.post<{ result: boolean }>(
        `company/check-child/${token}`,
        student
      )
      return response.data.result
    } catch (e) {
      console.error(e)
    }
  }

  const handleSetError = (index: number) => {
    if (!errors.includes(index)) {
      const errorsCopy = [...errors]
      errorsCopy.push(index)

      setErrors(errorsCopy)
    }
  }

  const handleRemoveError = (index: number) => {
    if (errors.includes(index)) {
      const errorsCopy = [...errors]
      const indexToRemove = errorsCopy.indexOf(index)
      errorsCopy.splice(indexToRemove, 1)

      setErrors(errorsCopy)
    }
  }

  const handleBlur = async (student: Student) => {
    if (!student.validated && student.cid && student.birthdate) {
      const result = await checkChild(student)
      if (result) {
        setStatus?.(student.key, 'validated', true)
        setStatus?.(student.key, 'error', false)
        handleRemoveError(student.key)
        setStudentEditing(false)
      } else {
        setStatus?.(student.key, 'error', true)
        handleSetError(student.key)
      }
    }
  }

  const isInvalid = students.some(({ error }) => error)
  const isAllEmpty = !students.some(({ edited }) => edited)
  const isSomeValidated = students.some(({ validated }) => validated)
  const isAllFilledValidated = students
    .filter(({ cid }) => cid)
    .every(({ validated }) => validated)
  const firstValidated = students.find(({ validated }) => validated)

  const handleChangeCredentials = (
    value: null | string,
    type: 'cid' | 'birthdate',
    student: Student
  ) => {
    handleChange?.(student.key, type, value)
    setStatus?.(student.key, 'error', false)
    handleRemoveError(student.key)
    setStudentEditing(true)
  }

  const handleQuit = () => {
    window.location.href = `${LOGIN_COMPANY_NO_TOKEN}/${token}`
  }

  const digitRegex = /^[\d\/]+$/

  const handleChangeDate = (
    event: React.ChangeEvent<HTMLInputElement>,
    student: Student
  ) => {
    handleChangeCredentials(event.target.value, 'birthdate', student)
    if (digitRegex.test(event.target.value)) {
      void handleBlur(student)
    }
  }

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    student: Student
  ) => {
    if (event.key === 'Enter') {
      return digitRegex.test(event.target.value) && void handleBlur(student)
    }
  }

  return (
    <LayoutRegistration>
      <MainWrapper>
        <LeftRightButtons
          right={
            isSomeValidated &&
            isAllFilledValidated &&
            !isInvalid &&
            !isStudentEditing
              ? `/client/addresses/${token}/${firstValidated?.cid}`
              : undefined
          }
        >
          <Title>Student ID #/Rider ID # & DOB</Title>
          <HintText>
            Please enter the ID # and DOB of all your students/riders. You are
            allowed to register up to 10 students/riders.
          </HintText>
          <FlexContainer>
            <LeftBlock>
              <TitleContainer>
                <StyledSpan>Student ID #/Rider ID #</StyledSpan>
                <StyledSpan>Date of Birth</StyledSpan>
              </TitleContainer>
              <StudentsContainer>
                {students.map((student: Student) => (
                  <StudentDiv
                    error={errors.includes(student.key)}
                    key={student.key}
                  >
                    <StudentCID
                      key={student.key}
                      value={student.cid}
                      placeholder='Enter Student ID #/Rider ID #'
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeCredentials(value, 'cid', student)
                      }
                      onBlur={() => handleBlur(student)}
                      onKeyDown={(
                        event: React.KeyboardEvent<HTMLInputElement>
                      ) => {
                        if (event.key === 'Enter') {
                          void handleBlur(student)
                        }
                      }}
                    ></StudentCID>
                    <InputMask
                      mask='99/99/9999'
                      placeholder='MM/DD/YYYY'
                      key={student.key}
                      disabled={!student.cid}
                      value={student.birthdate}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeDate(event, student)
                      }
                      onBlur={() => handleBlur(student)}
                      onKeyDown={(
                        event: React.KeyboardEvent<HTMLInputElement>
                      ) => handleKeyDown(event, student)}
                      className='datePicker'
                    />
                  </StudentDiv>
                ))}
              </StudentsContainer>
            </LeftBlock>
            <RightBlock>
              {isInvalid && (
                <MessageText>
                  The highlighted ID & DOB are unknown. You need to register
                  your students with school/district prior to requesting bus
                  services. Please either remove or correct highlighted entries.
                </MessageText>
              )}
              {isAllEmpty && (
                <MessageText>
                  At least one ID and DOB combination is required.
                </MessageText>
              )}
            </RightBlock>
          </FlexContainer>
          <Row>
            <Col span={4} offset={20}>
              <Button
                background={`${COLORS.BUTTON_GRAY}`}
                disabledBackground={`${COLORS.BUTTON_GRAY}`}
                width='100px'
                name='Quit'
                type='submit'
                onClick={() => setCancelModal(true)}
              />
            </Col>
          </Row>
        </LeftRightButtons>
        <ModalWindow
          width={372}
          mgTop={95}
          visible={cancelModal}
          onCloseClick={() => setCancelModal(false)}
          onNoClick={() => setCancelModal(false)}
          onYesClick={() => {
            handleQuit()
            setCancelModal(false)
          }}
        >
          <ModalContainer>
            <ModalTitle>Quit</ModalTitle>
            <ModalText>All your changes will be lost, are you sure?</ModalText>
          </ModalContainer>
        </ModalWindow>
      </MainWrapper>
    </LayoutRegistration>
  )
}
