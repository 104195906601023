import { COLORS } from 'common/const/colors.const'
import arrowLeft from 'common/icons/svg/arrowLeft.svg'
import arrowLeftWhite from 'common/icons/svg/arrowLeftWhite.svg'
import arrowRight from 'common/icons/svg/arrowRightButton.svg'
import arrowRightWhite from 'common/icons/svg/arrowRightWhite.svg'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const NavigationButton = styled(Link)<{ left?: boolean }>`
  display: block;
  width: 64px;
  height: 64px;
  background: ${COLORS.WHITE}
    ${({ left }) =>
      `url(${left ? arrowLeft : arrowRight}) no-repeat center center;`};
  border-radius: 6px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.19);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${({ left }) => (left ? '-132px' : 'inherit')};
  right: ${({ left }) => (!left ? '-132px' : 'inherit')};
  &:hover {
    background: ${COLORS.LIGHT_BLUE}
      ${({ left }) =>
        `url(${
          left ? arrowLeftWhite : arrowRightWhite
        }) no-repeat center center;`};
  }
`
