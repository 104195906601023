import { COLORS } from 'common/const/colors.const'
import styled from 'styled-components'

export const ModalBlock = styled.div<{
  width?: number
  mgTop?: number
}>`
  width: ${({ width }) => `${width}px`};
  margin-top: ${({ mgTop }) => `${mgTop}px`};
  padding: 16px;
  position: relative;
  background-color: ${COLORS.WHITE};
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
`

export const ModalBackground = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 5;
`

export const ModalCancelBlock = styled.div`
  position: absolute;
  top: -31px;
  right: -31px;
`

export const CancelIcon = styled.img`
  width: 31px;
  height: 31px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`

export const ButtonsFooter = styled.div`
  padding-top: 35px;
  display: flex;
  justify-content: center;
`

export const ButtonBlock = styled.div`
  width: 194px;
  display: flex;
  justify-content: space-around;
`
