import React, { FC } from 'react'

import {
  Label,
  TextAreaContainer,
  TextAreaField,
  TextAreaWrapper,
} from './TextArea.styled'
import { Props } from './TextArea.types'

export const TextArea: FC<Props> = ({
  name,
  label,
  height = 70,
  width = 412,
  children,
  ...props
}) => {
  return (
    <TextAreaWrapper width={width}>
      <Label htmlFor={name}>{label}</Label>
      <TextAreaContainer>
        <TextAreaField name={name} height={height} {...props} />
        {children}
      </TextAreaContainer>
    </TextAreaWrapper>
  )
}
