import axios from 'common/api/api'
import { requestStatuses } from 'common/const/request'
import { Layout } from 'layouts/Layout'
import React, { useEffect, useState } from 'react'

import { DropDown } from './components/DropDown'
import { RequestsInfo } from './components/RequestInfo'
import { RequestModal } from './components/RequestModal'
import {
  MainWrapper,
  StyledSpan,
  Title,
  TitleContainer,
  TitleLeftBlock,
  TitleRightBlock,
} from './Requests.styled'
import { RequestsResponse, RequestType } from './Requests.types'

const options = [
  { value: -1, label: 'All Requests' },
  { value: 1, label: 'New' },
  { value: 2, label: 'In Review' },
  { value: 3, label: 'Accepted' },
  { value: 4, label: 'Rejected' },
]

export const Requests = () => {
  const [requests, setRequests] = useState<RequestType[]>([])
  const [currentRequest, setCurrentRequest] = useState<
    RequestType | undefined
  >()
  const [visible, setVisible] = useState(false)
  const [selectedOption, setSelectedOption] = useState<number>(options[0].value)

  const getRequests = async () => {
    try {
      const response = await axios.get<RequestsResponse>(`requests/index`)
      const data = response?.data

      if (data) {
        setRequests(data)
      }
    } catch (e) {
      console.error(e)
    }
  }
  useEffect(() => {
    void getRequests()
  }, [])

  const filteredRequests =
    selectedOption !== -1
      ? requests?.filter(({ status }) => status === selectedOption)
      : requests

  return (
    <>
      <Layout isNavDisabled>
        <MainWrapper>
          <Title>Requests</Title>
          <DropDown
            options={options}
            setOption={setSelectedOption}
            selectedOption={selectedOption}
          />
          <TitleContainer>
            <TitleLeftBlock>
              <StyledSpan>Status</StyledSpan>
              <StyledSpan>Child</StyledSpan>
            </TitleLeftBlock>
            <TitleRightBlock>
              <StyledSpan>Change Request</StyledSpan>
              <StyledSpan>Date</StyledSpan>
            </TitleRightBlock>
          </TitleContainer>
          {filteredRequests?.map(item => {
            const {
              status,
              childName,
              date,
              messageCount,
              hasNewMessages,
              id,
              changes,
            } = item

            return (
              <RequestsInfo
                key={id}
                requestStatus={requestStatuses[status]}
                onClick={() => {
                  setCurrentRequest(item)
                  setVisible(true)
                }}
                newMessages={hasNewMessages}
                requestDate={date}
                child={childName}
                requestMessages={messageCount}
                requestChangeType={changes[0].type}
                requestChangedField={{
                  ...(changes[0].oldFields && changes[0].oldFields),
                  ...(changes[0].newFields && changes[0].newFields),
                }}
                requestEntityType={changes[0].entityType === 'note'}
              />
            )
          })}
        </MainWrapper>
      </Layout>
      <RequestModal
        visible={visible}
        onCloseClick={() => setVisible(false)}
        updateRequests={getRequests}
        requestData={currentRequest}
      />
    </>
  )
}
