import 'react-phone-input-2/lib/style.css'

import { FC } from 'react'
import PhoneInput from 'react-phone-input-2'

import { AlertError, Input, InputWrapper, Label } from './InputWithLabel.styled'
import { Props } from './InputWIthLabel.types'

export const InputWithLabel: FC<Props> = ({
  name,
  label,
  width = 172,
  errorBorder,
  phoneNumber,
  phoneNumberError,
  required,
  setFieldValue,
  onCustomChange,
  errorAlert,
  ...props
}) => {
  const requiredLabel = required ? (
    <span style={{ color: 'red' }}>*</span>
  ) : null

  const handleChange = (value: string) => {
    if (onCustomChange) {
      onCustomChange(value)
    } else {
      if (name) {
        setFieldValue?.(name, value)
      }
    }
  }

  return (
    <InputWrapper width={width}>
      <Label htmlFor={name}>
        {label}
        {requiredLabel}
      </Label>
      {phoneNumber ? (
        <PhoneInput
          inputStyle={{
            borderRadius: '2px',
            fontFamily: 'HelveticaNeue, sans-serif',
            fontSize: '14px',
            width: '100%',
            height: '39px',
            border: phoneNumberError,
          }}
          placeholder={''}
          disableDropdown={true}
          onBlur={e => {
            if (name) {
              props?.onBlur?.({ ...e, target: { ...e.target, name } })
            }
          }}
          country={'us'}
          onChange={handleChange}
          value={props.value?.toString()}
        />
      ) : (
        <Input errorBorder={errorBorder} id={name} {...props} />
      )}
      <AlertError>{errorAlert}</AlertError>
    </InputWrapper>
  )
}
