import moment from 'moment/moment'
import React, {
  createContext,
  FC,
  FormEvent,
  PropsWithChildren,
  useState,
} from 'react'

import {
  GuardianInfo,
  GuardianInfoTypes,
  RegistrationFormProps,
  Student,
  StudentAddress,
} from './RegistrationProvider.types'

const initialStudents = Array.from({ length: 10 }, (_, i) => ({
  key: i,
  cid: '',
  birthdate: '',
  addresses: [],
  editingPickUp: true,
  editingDropOff: true,
  validated: false,
  edited: false,
  error: false,
  addressesType: 3,
}))

export const RegistrationContext = createContext<RegistrationFormProps>({
  students: [],
  handleChange: () => {},
  setStudents: () => {},
  setStatus: () => {},
  setAddresses: () => {},
  setGuardianInformation: () => {},
  setGuardianContacts: () => {},
  setAddressesType: () => {},
  setAddressesEditing: () => {},
  guardianInfo: {
    firstGuardianName: '',
    firstGuardianPhone: '',
    firstGuardianWorkPhone: '',
    firstGuardianEmail: '',
    secondGuardianName: '',
    secondGuardianPhone: '',
    secondGuardianWorkPhone: '',
    secondGuardianEmail: '',
    emergencyContactName: '',
    emergencyContactPhone: '',
    emergencyContactWorkPhone: '',
    emergencyContactEmail: '',
  },
})

export const RegistrationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [students, setStudents] = useState<Student[]>(initialStudents)
  const [guardianInfo, setGuardianInfo] = useState<GuardianInfo>({
    firstGuardianName: '',
    firstGuardianPhone: '',
    firstGuardianWorkPhone: '',
    firstGuardianEmail: '',
    secondGuardianName: '',
    secondGuardianPhone: '',
    secondGuardianWorkPhone: '',
    secondGuardianEmail: '',
    emergencyContactName: '',
    emergencyContactPhone: '',
    emergencyContactWorkPhone: '',
    emergencyContactEmail: '',
  })

  const setStatus = (
    index: number,
    type: 'validated' | 'edited' | 'error',
    value: boolean
  ) => {
    const studentsCopy = [...students]
    const student = students.find(({ key }) => key === index)
    if (student) {
      student[type] = value
    }

    studentsCopy[index] = student as Student
    setStudents(studentsCopy)
  }
  const handleChange = (
    index: number,
    type: 'cid' | 'birthdate',
    value: moment.Moment | null | string
  ) => {
    const studentsCopy = [...students]
    const student = students.find(({ key }) => key === index)
    if (!student) return

    if (typeof value === 'string') {
      student[type] = value
    }
    student.validated = false

    student.edited = !(!student.cid && !student.birthdate)
    studentsCopy[index] = student
    setStudents(studentsCopy)
  }

  const setAddresses = (cid: string, addresses: StudentAddress[]) => {
    const studentsCopy = [...students]
    const student = students.find(({ cid: studentCid }) => studentCid === cid)
    if (!student) return
    student.addresses = addresses
    studentsCopy[student.key] = student
    setStudents(studentsCopy)
  }

  const setAddressesType = (cid: string, addressesType: number) => {
    const studentsCopy = [...students]
    const student = students.find(({ cid: studentCid }) => studentCid === cid)
    if (!student) return

    student.addressesType = addressesType
    studentsCopy[student.key] = student
    setStudents(studentsCopy)
  }
  const setAddressesEditing = (
    cid: string,
    addressType: number,
    editing: boolean
  ) => {
    const studentsCopy = [...students]
    const student = students.find(({ cid: studentCid }) => studentCid === cid)
    if (!student) return
    if (addressType === 1) student.editingPickUp = editing
    if (addressType === 2) student.editingDropOff = editing
    studentsCopy[student.key] = student
    setStudents(studentsCopy)
  }

  const setGuardianInformation = (e: FormEvent, name: GuardianInfoTypes) => {
    const { value } = e.target as HTMLInputElement
    setGuardianInfo({
      ...guardianInfo,
      [name]: value,
    })
  }

  const setGuardianContacts = (value: string, name: GuardianInfoTypes) => {
    setGuardianInfo({
      ...guardianInfo,
      [name]: value,
    })
  }

  return (
    <RegistrationContext.Provider
      value={{
        students,
        guardianInfo,
        handleChange,
        setStudents,
        setStatus,
        setAddresses,
        setGuardianInformation,
        setGuardianContacts,
        setAddressesType,
        setAddressesEditing,
      }}
    >
      {children}
    </RegistrationContext.Provider>
  )
}
