import { COLORS } from 'common/const/colors.const'
import { requestFields } from 'common/const/request'
import arrowRight from 'common/icons/svg/arrowRight.svg'
import message from 'common/icons/svg/message.svg'
import messageYellow from 'common/icons/svg/messageYellow.svg'
import { RequestFieldKeys } from 'common/types/request'
import { format } from 'date-fns'
import React, { FC } from 'react'

import {
  Icon,
  MessagesCountBlock,
  RequestDiv,
  RequestMessageSpan,
  StatusColor,
  StatusSpan,
  StyledSpan,
  TitleLeftBlock,
  TitleRightBlock,
} from './RequestInfo.styled'
import { Props } from './RequstsInfo.types'

export const RequestsInfo: FC<Props> = ({
  newMessages,
  onClick,
  requestMessages,
  requestDate,
  requestStatus,
  child,
  requestChangeType,
  requestChangedField,
  requestEntityType,
}) => {
  const requestKey = Object.keys(requestChangedField)[0]
  const requestMessage =
    `${
      requestEntityType
        ? 'Notes to transportation'
        : requestFields[requestKey as RequestFieldKeys]
    } ${requestChangeType}d to ${
      requestChangedField[requestKey as RequestFieldKeys]
    }`.slice(0, 23) + '...'

  return (
    <RequestDiv onClick={onClick}>
      <TitleLeftBlock>
        <StatusSpan>
          {(requestStatus === 'Accepted' || requestStatus === 'Rejected') && (
            <StatusColor
              color={
                requestStatus === 'Accepted'
                  ? COLORS.STATUS_GREEN
                  : COLORS.STATUS_RED
              }
            />
          )}
          {requestStatus}
        </StatusSpan>
        <MessagesCountBlock>
          {newMessages ? <Icon src={messageYellow} /> : <Icon src={message} />}
          <StyledSpan>{requestMessages}</StyledSpan>
        </MessagesCountBlock>
        <StyledSpan>{child}</StyledSpan>
      </TitleLeftBlock>
      <TitleRightBlock>
        <RequestMessageSpan>{requestMessage}</RequestMessageSpan>
        <StyledSpan>
          {requestDate ? format(requestDate * 1000, 'MM/dd/yyyy') : ''}
        </StyledSpan>
        <Icon src={arrowRight} />
      </TitleRightBlock>
    </RequestDiv>
  )
}
