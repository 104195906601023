import { COLORS } from 'common/const/colors.const'
import styled from 'styled-components'

export const MainInfoBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`

export const HintText = styled.div`
  padding-bottom: 20px;
  color: ${COLORS.HINT_TEXT_GREEN};
`

export const StudentIdBlock = styled.div`
  color: ${COLORS.MAIN_TEXT_BLUE};
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.15px;
  border: 1px solid ${COLORS.DARK_BLUE};
  padding: 8px 16px;
`

export const SelectionBlock = styled.div`
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
`

export const Label = styled.label`
  font-family: HelveticaNeue, sans-serif;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 2px;
`

export const StudentIdYellowBlock = styled.span`
  color: ${COLORS.ORANGE};
`

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const LeftBlock = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const RightBlock = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
`

export const StudentsContainer = styled.div`
  width: 350px;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;
  width: 350px;
  padding: 15px 0 15px 48px;
  background-color: ${COLORS.DARK_BLUE};
`

export const StudentDiv = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 20px 0 20px 46px;
  margin-top: 1px;
  background-color: ${COLORS.WHITE};
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.14);
  &:nth-child(even) {
    background-color: ${COLORS.LIGHT_GRAY};
  }
`

export const StudentID = styled.span`
  width: 50%;
  font-family: HelveticaNeue, sans-serif;
  color: ${COLORS.BLACK};
  font-size: 14px;
`

export const StyledSpan = styled.span`
  width: 50%;
  font-family: HelveticaNeue-Medium, sans-serif;
  color: ${COLORS.WHITE};
  font-size: 16px;
`

export const AddressErrorMessage = styled.span`
  font-family: HelveticaNeue, sans-serif;
  font-size: 12px;
  color: ${COLORS.RED};
  display: block;
`
