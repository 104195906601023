import { COLORS } from 'common/const/colors.const'
import {
  InputContainer,
  InputsBlock,
  RadioButtonBLock,
} from 'common/styles/common.styled'
import { getBorder } from 'common/utils/auth'
import { InputWithLabel } from 'components/InputWithLabel'
import { RadioButton } from 'components/RadioButton'
import { TextArea } from 'components/TextArea'
import React, { FC } from 'react'

import { Props } from './GeneralInfoForm.types'

export const GeneralInfoForm: FC<Props> = ({
  values,
  handleBlur,
  handleChange,
  setFieldValue,
  errors,
  touched,
}) => {
  const handleCustomChange = (e: React.ChangeEvent<any>) => {
    handleChange(e)
  }

  return (
    <form>
      <RadioButtonBLock>
        <RadioButton
          checked={values?.isRiding as boolean}
          onChange={() => setFieldValue('isRiding', true)}
          name='isRiding'
          label='Riding'
        />
        <RadioButton
          checked={!values?.isRiding}
          onChange={() => setFieldValue('isRiding', false)}
          name='isRiding'
          label='Not Riding'
        />
      </RadioButtonBLock>

      <InputsBlock>
        <InputContainer>
          <InputWithLabel
            onChange={handleCustomChange}
            onBlur={handleBlur}
            width={200}
            label='First Guardian'
            name='firstGuardianName'
            value={values.firstGuardianName}
          />
        </InputContainer>
        <InputContainer>
          <InputWithLabel
            setFieldValue={setFieldValue}
            phoneNumber
            phoneNumberError={getBorder(
              errors.firstGuardianPhone,
              COLORS.RED,
              touched.firstGuardianPhone
            )}
            onChange={handleCustomChange}
            onBlur={handleBlur}
            name='firstGuardianPhone'
            label='Cellphone'
            value={values.firstGuardianPhone}
            errorAlert={touched.firstGuardianPhone && errors.firstGuardianPhone}
          />
        </InputContainer>
        <InputContainer>
          <InputWithLabel
            setFieldValue={setFieldValue}
            phoneNumber
            phoneNumberError={getBorder(
              errors.firstGuardianWorkPhone,
              COLORS.RED,
              touched.firstGuardianWorkPhone
            )}
            onChange={handleCustomChange}
            onBlur={handleBlur}
            label='Work Phone'
            value={values.firstGuardianWorkPhone}
            name='firstGuardianWorkPhone'
            errorAlert={
              touched.firstGuardianWorkPhone && errors.firstGuardianWorkPhone
            }
          />
        </InputContainer>
        <InputContainer>
          <InputWithLabel
            onChange={handleCustomChange}
            onBlur={handleBlur}
            errorBorder={
              !!errors.firstGuardianEmail && !!touched.firstGuardianEmail
            }
            label='Email'
            name='firstGuardianEmail'
            value={values.firstGuardianEmail}
            errorAlert={touched.firstGuardianEmail && errors.firstGuardianEmail}
          />
        </InputContainer>
      </InputsBlock>
      <InputsBlock>
        <InputContainer>
          <InputWithLabel
            onChange={handleCustomChange}
            onBlur={handleBlur}
            width={200}
            label='Second Guardian'
            name='secondGuardianName'
            value={values.secondGuardianName}
          />
        </InputContainer>
        <InputContainer>
          <InputWithLabel
            setFieldValue={setFieldValue}
            phoneNumber
            phoneNumberError={getBorder(
              errors.secondGuardianPhone,
              COLORS.RED,
              touched.secondGuardianPhone
            )}
            onChange={handleCustomChange}
            onBlur={handleBlur}
            label='Cellphone'
            name='secondGuardianPhone'
            value={values.secondGuardianPhone}
            errorAlert={
              touched.secondGuardianPhone && errors.secondGuardianPhone
            }
          />
        </InputContainer>
        <InputContainer>
          <InputWithLabel
            setFieldValue={setFieldValue}
            phoneNumber
            phoneNumberError={getBorder(
              errors.secondGuardianWorkPhone,
              COLORS.RED,
              touched.secondGuardianWorkPhone
            )}
            onChange={handleCustomChange}
            onBlur={handleBlur}
            label='Work Phone'
            name='secondGuardianWorkPhone'
            value={values.secondGuardianWorkPhone}
            errorAlert={
              touched.secondGuardianWorkPhone && errors.secondGuardianWorkPhone
            }
          />
        </InputContainer>
        <InputContainer>
          <InputWithLabel
            onChange={handleCustomChange}
            onBlur={handleBlur}
            errorBorder={
              !!errors.secondGuardianEmail && !!touched.secondGuardianEmail
            }
            label='Email'
            name='secondGuardianEmail'
            value={values.secondGuardianEmail}
            errorAlert={
              touched.secondGuardianEmail && errors.secondGuardianEmail
            }
          />
        </InputContainer>
      </InputsBlock>
      <InputsBlock>
        <InputContainer>
          <InputWithLabel
            onChange={handleCustomChange}
            onBlur={handleBlur}
            width={200}
            label='Emergency Contact'
            name='emergencyContactName'
            value={values.emergencyContactName}
          />
        </InputContainer>
        <InputContainer>
          <InputWithLabel
            phoneNumber
            phoneNumberError={getBorder(
              errors.emergencyContactPhone,
              COLORS.RED,
              touched.emergencyContactPhone
            )}
            setFieldValue={setFieldValue}
            onChange={handleCustomChange}
            onBlur={handleBlur}
            label='Cellphone'
            name='emergencyContactPhone'
            value={values.emergencyContactPhone}
            errorAlert={
              touched.emergencyContactPhone && errors.emergencyContactPhone
            }
          />
        </InputContainer>
        <InputContainer>
          <InputWithLabel
            phoneNumber
            phoneNumberError={getBorder(
              errors.emergencyContactWorkPhone,
              COLORS.RED,
              touched.emergencyContactWorkPhone
            )}
            setFieldValue={setFieldValue}
            onChange={handleCustomChange}
            onBlur={handleBlur}
            label='Work Phone'
            name='emergencyContactWorkPhone'
            value={values.emergencyContactWorkPhone}
            errorAlert={
              touched.emergencyContactWorkPhone &&
              errors.emergencyContactWorkPhone
            }
          />
        </InputContainer>
        <InputContainer>
          <InputWithLabel
            onChange={handleCustomChange}
            onBlur={handleBlur}
            errorBorder={
              !!errors.emergencyContactEmail && !!touched.emergencyContactEmail
            }
            label='Email'
            name='emergencyContactEmail'
            value={values.emergencyContactEmail}
            errorAlert={
              touched.emergencyContactEmail && errors.emergencyContactEmail
            }
          />
        </InputContainer>
      </InputsBlock>
      <TextArea
        onChange={handleCustomChange}
        onBlur={handleBlur}
        label='Any special needs, such as health concerns, or special equipment your rider’s bus driver needs to know:'
        name='alertNote'
        value={values.alertNote}
      />
    </form>
  )
}
