import { COLORS } from 'common/const/colors.const'
import React, { FC } from 'react'

import { Button } from '../Button'
import { ButtonDiv, ButtonsBlock, ButtonsWrapper } from './ActionButtons.styled'
import { Props } from './ActionButtons.types'

export const ActionButtons: FC<Props> = ({
  onSave,
  onCancel,
  onExit,
  visible = false,
}) => {
  if (!visible) return null

  return (
    <ButtonsWrapper>
      <ButtonsBlock>
        {onSave && (
          <ButtonDiv>
            <Button name='Save' width='92px' onClick={onSave} />
          </ButtonDiv>
        )}
        {onCancel && (
          <ButtonDiv>
            <Button
              name='Cancel'
              width='92px'
              onClick={onCancel}
              background={`${COLORS.BUTTON_GRAY}`}
              hoverColor={`${COLORS.BUTTON_GRAY}`}
              hoverBorder={`${COLORS.BUTTON_GRAY}`}
            />
          </ButtonDiv>
        )}
        {onExit && (
          <ButtonDiv>
            <Button
              name='Exit'
              width='92px'
              onClick={onExit}
              background={`${COLORS.LIGHT_BLUE}`}
              hoverColor={`${COLORS.LIGHT_BLUE}`}
              hoverBorder={`${COLORS.LIGHT_BLUE}`}
            />
          </ButtonDiv>
        )}
      </ButtonsBlock>
    </ButtonsWrapper>
  )
}
