import { COLORS } from 'common/const/colors.const'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const HeaderWrapper = styled.div`
  display: flex;
  padding: 0 23px 0 30px;
  justify-content: space-between;
  height: 80px;
  position: fixed;
  z-index: 1;
  top: 0;
  background-color: ${COLORS.WHITE};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.21);
  width: 100%;
`

export const LogoIcon = styled.img`
  width: 188px;
  height: 46px;
  margin: 17px 0;
`

export const LogoContainer = styled.div`
  width: 218px;
  height: 80px;
  margin-right: 30px;
`

export const DisabledNavigationLink = styled.span`
  display: flex;
  align-items: center;
  font-family: Helvetica Neue, sans-serif;
  font-size: 16px;
  color: ${COLORS.DISABLED};
  margin-right: 50px;
  cursor: not-allowed;
  &:last-child {
    margin-right: 0;
  }
`

export const NavigationLink = styled(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  font-family: Helvetica Neue, sans-serif;
  font-size: 16px;
  color: ${COLORS.DARK_BLUE};
  text-decoration: none;
  margin-right: 50px;
  &:last-child {
    margin-right: 0;
  }
  &:before {
    position: absolute;
    display: none;
    content: '';
    width: 100%;
    height: 5px;
    background: ${COLORS.LIGHT_BLUE};
    bottom: 0;
    left: 0;
  }
  &.active {
    font-family: HelveticaNeue Neue, sans-serif;
    font-weight: bold;
    &:before {
      display: block;
    }
  }
`

export const RequestsLink = styled(NavLink)`
  display: flex;
  position: relative;
  align-items: center;
  font-family: Helvetica Neue, sans-serif;
  font-size: 16px;
  color: ${COLORS.DARK_BLUE};
  text-decoration: none;
  margin-right: 40px;
  &:last-child {
    margin-right: 0;
  }
  &.active {
    font-family: HelveticaNeue Neue, sans-serif;
    font-weight: bold;
  }
`

export const LogoutLink = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  font-family: Helvetica Neue, sans-serif;
  font-size: 16px;
  color: ${COLORS.DARK_BLUE};
  text-decoration: none;
  margin-right: 40px;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
  &.active {
    font-family: HelveticaNeue Neue, sans-serif;
    font-weight: bold;
  }
`

export const NavigationContainer = styled.div`
  height: 80px;
  display: flex;
  justify-content: space-between;
`

export const RequestsCounter = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 17px;
  right: -16px;
  border-radius: 50%;
  font-size: 9px;
  color: ${COLORS.WHITE};
  width: 16px;
  height: 16px;
  background-color: #f87a03;
}
`

export const RequestsContainer = styled.div`
  height: 80px;
  display: flex;
  justify-content: space-between;
`
