import { COLORS } from 'common/const/colors.const'
import styled from 'styled-components'

export const InfoBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const InfoBlockLabel = styled.span`
  color: ${COLORS.GRAY_WHITE};
  font-family: Helvetica Neue, sans-serif;
  font-size: 12px;
  margin-bottom: 2px;
`

export const InfoBlockValue = styled.span`
  color: ${COLORS.BLACK};
  font-family: Helvetica Neue, sans-serif;
  font-size: 14px;
`
