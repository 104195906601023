import { COLORS } from 'common/const/colors.const'
import styled from 'styled-components'

export const RequestDiv = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 1px;
  background-color: ${COLORS.WHITE};
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.14);
  &:nth-child(even) {
    background-color: ${COLORS.LIGHT_GRAY};
  }
`

export const TitleLeftBlock = styled.div`
  width: 50%;
  padding: 20px 0 19px 20px;
  display: flex;
`

export const TitleRightBlock = styled.div`
  width: 50%;
  padding: 20px 17px 19px 0;
  display: flex;
  justify-content: space-between;
`

export const StatusColor = styled.div<{ color?: string }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -12px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`

export const MessagesCountBlock = styled.div`
  width: 30px;
  display: flex;
  justify-content: space-between;
  padding-right: 67px;
  padding-left: 23px;
`

export const Icon = styled.img`
  padding-right: 5px;
`

export const StyledSpan = styled.span`
  font-family: HelveticaNeue, sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: ${COLORS.BLACK};
`

export const RequestMessageSpan = styled.span`
  font-family: HelveticaNeue, sans-serif;
  width: 191px;
  font-size: 14px;
  font-weight: bold;
  color: ${COLORS.BLACK};
`

export const StatusSpan = styled.span`
  font-family: HelveticaNeue, sans-serif;
  position: relative;
  font-size: 14px;
  font-weight: bold;
  color: ${COLORS.BLACK};
  width: 66px;
`
