import React, { FC } from 'react'

import {
  InfoBlockLabel,
  InfoBlockValue,
  InfoBlockWrapper,
} from './InfoBlock.styled'
import { Props } from './InfoBlock.types'

export const InfoBlock: FC<Props> = ({ label, value }) => {
  const content = typeof value === 'boolean' ? (value ? 'On' : 'Off') : value
  return (
    <InfoBlockWrapper>
      <InfoBlockLabel>{label}</InfoBlockLabel>
      <InfoBlockValue>{content}</InfoBlockValue>
    </InfoBlockWrapper>
  )
}
