import { COLORS } from 'common/const/colors.const'
import styled from 'styled-components'

export const StyledButton = styled.button<{
  background: string
  border: string
  color: string
  hoverBorder: string
  hoverColor: string
  hoverBackground: string
  width: string
  height: string
  disabledBackground: string
  fontSize?: string
}>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: ${({ border }) => border};
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ color }) => color};
  cursor: pointer;
  background: ${({ background }) => background};
  &:hover {
    background: ${({ hoverBackground }) => hoverBackground};
    border: 1px solid ${({ hoverBorder }) => hoverBorder};
    color: ${({ hoverColor }) => hoverColor};
    transition: 0.5s;
  }
  &:disabled {
    cursor: not-allowed;
    color: ${COLORS.WHITE};
    border-color: ${({ disabledBackground }) => disabledBackground};
    background: ${({ disabledBackground }) => disabledBackground};
  }
`
