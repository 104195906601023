import { COLORS } from 'common/const/colors.const'
import React, { FC, PropsWithChildren } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'

import cancelIcon from '../../common/icons/svg/cancelIcon.svg'
import { Button } from '../Button'
import {
  ButtonBlock,
  ButtonsFooter,
  CancelIcon,
  ModalBackground,
  ModalBlock,
  ModalCancelBlock,
} from './ModalWindow.styled'
import { Props } from './ModalWindow.types'

export const ModalWindow: FC<PropsWithChildren<Props>> = ({
  children,
  visible,
  onCloseClick,
  onNoClick,
  onYesClick,
  onOkClick,
  width = 600,
  mgTop = 54,
  disabled,
}) => {
  if (!visible) return null
  return (
    <ModalBackground>
      <OutsideClickHandler onOutsideClick={onCloseClick}>
        <ModalBlock width={width} mgTop={mgTop}>
          <ModalCancelBlock>
            <CancelIcon onClick={onCloseClick} src={cancelIcon} />
          </ModalCancelBlock>
          {children}
          {(onNoClick || onYesClick || onOkClick) && (
            <ButtonsFooter>
              <ButtonBlock>
                {onYesClick && (
                  <Button
                    name='Yes'
                    disabled={disabled}
                    onClick={onYesClick}
                    width='92px'
                  />
                )}

                {onNoClick && (
                  <Button
                    name='No'
                    disabled={disabled}
                    onClick={onNoClick}
                    width='92px'
                    background={`${COLORS.BUTTON_GRAY}`}
                    hoverColor={`${COLORS.BUTTON_GRAY}`}
                    hoverBorder={`${COLORS.BUTTON_GRAY}`}
                  />
                )}
                {onOkClick && (
                  <Button name='OK' onClick={onOkClick} width='92px' />
                )}
              </ButtonBlock>
            </ButtonsFooter>
          )}
        </ModalBlock>
      </OutsideClickHandler>
    </ModalBackground>
  )
}
