import {
  CLIENT,
  HOME_PAGE,
  NOTES,
  PICK_UP,
} from 'common/const/navigation.consts'
import { useFormContext } from 'common/hooks/useFormContext'
import { useStudentsContext } from 'common/hooks/useStudentsContext'
import { MainWrapper, Title } from 'common/styles/common.styled'
import { Student } from 'common/types/students'
import { InfoBlock } from 'components/InfoBlock'
import { LeftRightButtons } from 'components/LeftRightButtons'
import { Layout } from 'layouts/Layout'
import React from 'react'
import { useParams } from 'react-router-dom'

import { GeneralInfoForm } from './components/GeneralInfoForm'
import {
  Avatar,
  AvatarText,
  InfoItemWrapper,
  MainInfoBlock,
} from './GeneralInfo.styled'

export const GeneralInfo = () => {
  const {
    generalInfoState: {
      handleChange,
      handleBlur,
      setFieldValue,
      values,
      errors,
      touched,
    },
  } = useFormContext()

  const { students } = useStudentsContext()
  const { id } = useParams()
  const findStudent = (student: Student) => {
    return student.id === Number(id)
  }

  const student: Student | undefined = students.find(findStudent)

  const rightButton = `${CLIENT}/${id}${values?.isRiding ? PICK_UP : NOTES}`

  return (
    <Layout isAddressNavDisabled={!values?.isRiding}>
      <MainWrapper>
        <LeftRightButtons left={HOME_PAGE} right={rightButton}>
          <Title>{student?.fullName}</Title>

          <MainInfoBlock>
            <Avatar>
              <AvatarText>{student?.fullName}</AvatarText>
            </Avatar>
            {student?.cid && (
              <InfoItemWrapper>
                <InfoBlock label='ID' value={student.cid} />
              </InfoItemWrapper>
            )}
            {student?.birthDate && (
              <InfoItemWrapper>
                <InfoBlock label='Birth Date' value={student.birthDate} />
              </InfoItemWrapper>
            )}
          </MainInfoBlock>

          <GeneralInfoForm
            errors={errors}
            touched={touched}
            values={values}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
          />
        </LeftRightButtons>
      </MainWrapper>
    </Layout>
  )
}
