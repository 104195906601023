import arrowDown from 'common/icons/svg/arrowDown.svg'
import React, { FC, useState } from 'react'

import {
  ArrowDownContainer,
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  ListItem,
} from './DropDown.styled'
import { Props } from './DropDown.types'

export const DropDown: FC<Props> = ({ options, setOption, selectedOption }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggling = () => setIsOpen(!isOpen)

  const onOptionClicked = (value: number) => {
    setOption(value)
    setIsOpen(false)
  }

  return (
    <DropDownContainer>
      <DropDownHeader onClick={toggling}>
        {options?.filter(({ value }) => value === selectedOption)[0].label}
        <ArrowDownContainer src={arrowDown} />
      </DropDownHeader>
      {isOpen && (
        <DropDownListContainer>
          <DropDownList>
            {options.map(({ value, label }) => (
              <ListItem
                onClick={() => onOptionClicked(value)}
                key={Math.random()}
              >
                {label}
              </ListItem>
            ))}
          </DropDownList>
        </DropDownListContainer>
      )}
    </DropDownContainer>
  )
}
