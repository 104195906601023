import { COLORS } from '../const/colors.const'

export type BorderError = (
  errors: string | undefined,
  border: string,
  touched?: boolean | null,
  requestError?: string | undefined
) => string

export const getBorder: BorderError = (
  errors,
  border,
  touched,
  requestError
) => {
  return (touched !== null ? errors && touched : errors) || requestError
    ? `1px solid ${border}`
    : `1px solid ${COLORS.GRAY}`
}
