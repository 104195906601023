export enum RequestFieldKeys {
  firstGuardianName = 'firstGuardianName',
  firstGuardianPhone = 'firstGuardianPhone',
  firstGuardianWorkPhone = 'firstGuardianWorkPhone',
  firstGuardianEmail = 'firstGuardianEmail',
  secondGuardianName = 'secondGuardianName',
  secondGuardianPhone = 'secondGuardianPhone',
  secondGuardianWorkPhone = 'secondGuardianWorkPhone',
  secondGuardianEmail = 'secondGuardianEmail',
  emergencyContactEmail = 'emergencyContactEmail',
  emergencyContactName = 'emergencyContactName',
  emergencyContactPhone = 'emergencyContactPhone',
  emergencyContactWorkPhone = 'emergencyContactWorkPhone',
  noteText = 'noteText',
  alertNote = 'alertNote',
  schoolName = 'schoolName',
  address = 'address',
  fullName = 'fullName',
  type = 'type',
  dropoffAddress = 'dropoffAddress',
  pickupAddress = 'pickupAddress',
  isRiding = 'isRiding',
}

export interface RequestChange {
  type: string
  entityType: string
  oldFields: RequestField
  newFields: RequestField
  entityId?: number
}

export interface CreateRequestType {
  riderId: number
  changes: RequestChange[]
}

export type RequestField = {
  [key in RequestFieldKeys]: string | number
}
