export const TOKEN = 'token'
export const CLIENT = '/client'
export const GENERAL = '/general'
export const PICK_UP = '/pickup'
export const NOTES = '/notes'
export const REQUESTS = `${CLIENT}/requests`
export const LOGIN = `${CLIENT}/login`
export const LOGIN_COMPANY = `${CLIENT}/login/:token`
export const LOGIN_COMPANY_NO_TOKEN = `${CLIENT}/login`
export const REGISTRATION = `${CLIENT}/registration/:token`
export const REGISTRATION_ADDRESSES = `${CLIENT}/addresses/:token/:cid`
export const REGISTRATION_NO_TOKEN = `${CLIENT}/registration`
export const GUARDIAN_INFO = `${CLIENT}/guardian/:token`
export const HOME_PAGE = `${CLIENT}`
export const GENERAL_INFO = `${CLIENT}/:id${GENERAL}`
export const PICK_UP_PAGE = `${CLIENT}/:id${PICK_UP}`
export const NOTES_TO_TRANSPORTATION = `${CLIENT}/:id${NOTES}`
