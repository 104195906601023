import { COLORS } from 'common/const/colors.const'
import Radio from 'common/icons/svg/radio.svg'
import styled from 'styled-components'

export const RadioIcon = styled.span`
  border: 2px solid #dbdbdb;
  border-radius: 50%;
  background: ${COLORS.WHITE};
  width: 20px;
  height: 20px;
  margin-right: 8px;
`

export const RadioButtonLabel = styled.label`
  font-size: 14px;
  font-family: Helvetica Neue, sans-serif;
  color: ${COLORS.BLACK};
  display: flex;
  align-items: center;
  input:checked ~ ${RadioIcon} {
    border: none;
    background: url(${Radio}) no-repeat center center;
    background-size: 20px;
  }
`

export const RadioButtonInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`

export const RadioButtonWrapper = styled.div`
  min-width: content-box;
  display: flex;
  align-items: center;
  &:not(:first-child) {
    margin-left: 28px;
  }
`
