import React, { FC } from 'react'

import {
  RadioButtonInput,
  RadioButtonLabel,
  RadioButtonWrapper,
  RadioIcon,
} from './RadioButton.styled'
import { Props } from './RadioButton.types'

export const RadioButton: FC<Props> = ({ name, label, id, ...props }) => {
  return (
    <RadioButtonWrapper>
      <RadioButtonLabel htmlFor={id}>
        <RadioButtonInput type='radio' name={name} id={id} {...props} />
        <RadioIcon />
        {label}
      </RadioButtonLabel>
    </RadioButtonWrapper>
  )
}
